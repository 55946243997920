<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div v-if="order" class="row p-3">
                    <div class="mb-4 d-none">
                        <a :href="vtApp()+'/dashboard/order/'+order.id" target="_blank" class="btn btn-sm btn-icon btn-info mr-3 ml-2" type="button">
                            View in Viraltrend
                        </a>
                        <button v-if="order.status == 'pending' || order.status == 'deactive'" @click="approvePost" class="btn btn-sm btn-icon btn-success" type="button">
                            Approve
                        </button>
                        <button v-if="order.status == 'active'" @click="rejectPost" class="btn btn-sm btn-icon btn-danger" type="button">
                            Reject
                        </button>
                        <button class="btn btn-sm btn-icon btn-primary" type="button">
                            Edit
                        </button>
                        <button class="btn btn-sm btn-icon btn-danger" type="button">
                            Delete
                        </button>
                    </div>
                    <template v-if="order">
                        <div class="col-sm-8 col-md-old-12 col-sm-old-12">
                            <div class="mt-2">
                                <h3 class="card-title text-uppercase text-muted mb-2">Order Info</h3>
                                <p class="">Order Product ID: {{ order.id }}</p>
                                <p class="">Name: {{ order.name }}</p>
                                <p class="">Category: {{ order.category }}</p>
                                <p class="">Order ID: {{ order.order_id }}</p>
                                <p class="">User ID: <a :href="'/dashboard/user/'+order.user_id ">{{ order.user_id }}</a></p>
                                <p class="">Image: </p>
                                <div>
                                    <img style="height: 50px" :src="order.image" />
                                </div>
                                <p class="">Currency: {{ order.currency }}</p>
                                <p class="">Amount: {{ order.amount }}</p>
                                <p class="">Number of items: {{ order.item_number }}</p>
                                <p class="" v-if="order.date_accepted">Date Accepted: {{ unixToUtc(order.date_accepted) }}</p>
                                <p class="" v-if="order.date_rejected">Date Rejected: {{ unixToUtc(order.date_rejected) }}</p>
                                <p class="" v-if="order.date_refunded">Date Refunded: {{ unixToUtc(order.date_refunded) }}</p>
                                <p class="" v-if="order.date_paid">Date Paid: {{ unixToUtc(order.date_paid) }}</p>
                                <p class="" v-if="order.date_shipped">Date Shipped: {{ unixToUtc(order.date_shipped) }}</p>
                                <p class="" v-if="order.date_delivered">Date Delivered: {{ unixToUtc(order.date_delivered) }}</p>
                                <p class="" v-if="order.date_received">Date Received: {{ unixToUtc(order.date_received) }}</p>
                                <p class="" v-if="order.date_disputed">Date Disputed: {{ unixToUtc(order.date_disputed) }}</p>
                                <p class="" v-if="order.date_confirmed">Date Confirmed: {{ unixToUtc(order.date_confirmed) }}</p>
                                <div>
                                    <button v-if="order.date_delivered && !order.date_received" @click="markAsReceivedByCustomer(order.id)" class="btn btn-sm btn-icon btn-info" type="button">
                                        <span class="btn-inner--icon">Received by Customer</span>
                                    </button>
                                    <button v-if="order.date_received && !order.date_confirmed" @click="paySeller()" class="btn btn-sm btn-icon btn-primary" type="button">
                                        <span class="btn-inner--icon">Pay Seller</span>
                                    </button>
                                    <button v-if="order.date_rejected && !order.date_shipped" @click="refundBuyer()" class="btn btn-sm btn-icon btn-danger" type="button">
                                        <span class="btn-inner--icon">Refund Buyer</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-old-12 col-sm-old-12">
                            <div class="mt-2" v-if="order.shop[0]">
                                <h3 class="card-title text-uppercase text-muted mb-2">Shop Info</h3>
                                <p class="">Shop Name: {{ order.shop[0].name }}</p>
                                <p class="">Description: {{ order.shop[0].description }}</p>
                                <p class="">Address: {{ order.shop[0].address }}</p>
                                <p class="">City: {{ order.shop[0].city }}</p>
                                <p class="">State: {{ order.shop[0].state }}</p>
                                <p class="">Country: {{ order.shop[0].country }}</p>
                                <p class="">Image: </p>
                                <div>
                                    <img style="height: 50px" :src="order.shop[0].image" />
                                </div>
                                <p class="">Approved: {{ order.shop[0].is_approved }}</p>
                            </div>
                            <div class="mt-2" v-if="order.date_disputed">
                                <h3 class="card-title text-uppercase text-muted mb-2">Dispute Conversation</h3>
                                <div class="">
                                    <div class="card chat-app">
                                        <div id="plist" class="people-list">
                                        </div>
                                        <div class="chat">
                                            <div class="chat-header clearfix">
                                            </div>
                                            <div class="chat-history">
                                                <ul class="m-b-0">
                                                    <template v-for="message in order.messages">
                                                      <li class="clearfix" v-if="message.sender_name == 'admin'">
                                                          <div class="message-data text-right">
                                                              <span class="message-data-time">{{ formatDateTime(message.created_at) }}</span>
                                                          </div>
                                                          <div class="message other-message float-right"> {{ message.message }} </div>
                                                      </li>
                                                      <li class="clearfix" v-else>
                                                          <div class="message-data">
                                                              <span class="message-data-time">{{ formatDateTime(message.created_at) }}, {{ message.sender_name }}</span>
                                                          </div>
                                                          <div class="message my-message">{{ message.message }}</div>
                                                      </li>
                                                    </template>
                                                </ul>
                                            </div>
                                            <div class="chat-message clearfix">
                                                <div class="input-group mb-0">
                                                    <div @click="orderDisputeComment" class="input-group-prepend mouse-pointer">
                                                        <span class="input-group-text"><i class="fa fa-paper-plane"></i></span>
                                                    </div>
                                                    <input v-model="adminDisputeMessage" type="text" class="form-control" placeholder="Enter text here...">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApiService from '@/services/api_service'

export default {
    name: "Order",
    components: {},
    data: () => ({
        selectedItem: null,
        id: "",
        message: "",
        loading: "",
        order: "",
        adminDisputeMessage: '',
        fields: [],
        withdrawal_fields: [
            "id",
            "user_id",
            "account_id",
            "amount",
            "wallet",
            "country",
            "status",
            "created_at"
        ]
    }),
    methods: {
        async getOrder() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetOrder(this.id)
                if (apiResponse['status'] == 'success') {
                    this.order = apiResponse['data']
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async orderDisputeComment() {
            this.loading = true
            try {
                var apiResponse = await ApiService.SendProductOrderMessage(this.id, this.adminDisputeMessage)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
            } finally {
                this.loading = false
            }
        },
        async paySeller() {
            this.loading = true
            try {
                var apiResponse = await ApiService.MarkProductOrder('send_vendor_payment', this.order.id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
            } finally {
                this.loading = false
            }
        },
        async refundBuyer() {
            this.loading = true
            try {
                var apiResponse = await ApiService.MarkProductOrder('refunded', this.order.id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
            } finally {
                this.loading = false
            }
        },
        async markAsReceivedByCustomer() {
            this.loading = true
            try {
                var apiResponse = await ApiService.MarkProductOrder('received', this.order.id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
            } finally {
                this.loading = false
            }
        },
    },
    mounted() {
        this.id = this.$route.params['id']
        this.getOrder(this.id.toString())
    }
}
</script>

<style scoped>
.chat-app .people-list {
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7
}

.chat-app .chat {
    margin-left: 0;
    border-left: 1px solid #eaeaea
}

.people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px
}

.people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer
}

.people-list .chat-list li.active {
    background: #efefef
}

.people-list .chat-list li .name {
    font-size: 15px
}

.people-list .chat-list img {
    width: 45px;
    border-radius: 50%
}

.people-list img {
    float: left;
    border-radius: 50%
}

.people-list .about {
    float: left;
    padding-left: 8px
}

.people-list .status {
    color: #999;
    font-size: 13px
}

.chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #f4f7f6
}

.chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px
}

.chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff
}

.chat .chat-history ul {
    padding: 0
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
    margin-bottom: 0px
}

.chat .chat-history .message-data {
    margin-bottom: 15px
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px
}

.chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .my-message {
    background: #efefef
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #efefef;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .other-message {
    background: #e8f1f3;
    text-align: right
}

.chat .chat-history .other-message:after {
    border-bottom-color: #e8f1f3;
    left: 93%
}

.chat .chat-message {
    padding: 20px
}

.online,
.offline,
.me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle
}

.online {
    color: #86c541
}

.offline {
    color: #e47297
}

.me {
    color: #1d8ecd
}

.float-right {
    float: right
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

@media only screen and (max-width: 767px) {
    .chat-app .people-list {
        height: 465px;
        width: 100%;
        overflow-x: auto;
        background: #fff;
        left: -400px;
        display: none
    }

    .chat-app .people-list.open {
        left: 0
    }

    .chat-app .chat {
        margin: 0
    }

    .chat-app .chat .chat-header {
        border-radius: 0.55rem 0.55rem 0 0
    }

    .chat-app .chat-history {
        height: 300px;
        overflow-x: auto
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app .chat-list {
        height: 650px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: 600px;
        overflow-x: auto
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app .chat-list {
        height: 480px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: calc(100vh - 350px);
        overflow-x: auto
    }
}
</style>
