<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="userTaskData['total_page']">{{ (parseInt(userTaskData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{userTaskData['total_user_tasks'].toLocaleString()}} {{ sectionName }}</h3>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allUserTasks" :key="item.id">
                                <td v-for="field in tableHeadings" :key="field">
                                    <template v-if="field == 'id'">
                                        <a :href="'/dashboard/advert/'+item[field]">
                                            {{ item[field] }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        {{ item[field] }}
                                    </template>
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "UserTask",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        type: "all",
        user_id: "",
        sectionName: ' User Tasks',
        allUserTasksData: [],
        allUserTasks: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        userTaskData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_transactions_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend UserTasks",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allUserTasks)
            this.exporting = false
        },
        async getUserTasks() {
            if(!this.user_id){
                this.notifyUser('warning', 'user_id is required')
                return
            }
            if(!this.type){
                this.notifyUser('warning', 'type is required')
                return
            }
            this.loading = true
            try {
                var apiResponse = await ApiService.GetUserTasks(this.user_id, this.page, this.type)
                if (apiResponse['status'] == 'success') {
                    this.userTaskData = apiResponse['data']
                    this.allUserTasks = this.userTaskData['user_tasks']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getUserTasks()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.userTaskData['total_page']) {
                this.page = this.page + 1
                this.getUserTasks()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getUserTasks();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "email",
                "social",
                "task",
                "user_currency",
                "user_amount",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.user_id = this.$route.params['user_id']
        this.type = this.$route.params['type']
        if(this.type == 'completed'){
            this.sectionName = 'Completed Tasks'
        }else if(this.type == 'all'){
            this.sectionName = 'Tasks'
        }
        this.getUserTasks()
    },
    watch: {}
}
</script>

<style scoped>
</style>
