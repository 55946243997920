<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-flexold">
                        
                        <div>
                            <div class="row">
                                <div class="col-sm-4">
                                    <p class="mr-3" v-if="walletsData['total_page']">{{ (parseInt(walletsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                                    <div>
                                        <div class="d-flex">
                                            <div>
                                                <select class="form-control" v-model="gte_field">
                                                    <option value="">Select Wallet</option>
                                                    <option value="amount">Amount Wallet</option>
                                                    <option value="usd">USD Wallet</option>
                                                    <option value="ngn">Naira Wallet</option>
                                                    <option value="cedi">Cedi Wallet</option>
                                                    <option value="shilling">Shilling Wallet</option>
                                                </select>
                                            </div>
                                            <div>
                                                <input class="form-control" v-model="gte_amount" placeholder="Amount" />
                                            </div>
                                            <div>
                                                <button @click="getWalletsByAmount" class="btn btn-sm-old btn-icon btn-primary" type="button">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 text-left">
                                    <p v-if="stats">
                                        VT Promoters NGN: {{ (parseInt(stats['total_amount'])).toLocaleString() }}<br/>
                                        VT Promoters Grant NGN : {{ (parseInt(stats['total_grant_amount'])).toLocaleString() }}
                                    </p>
                                </div>
                                <div class="col-sm-4 text-right">
                                    <p v-if="stats">
                                        VT Social Cedi: {{ (parseInt(stats['total_cedi'])).toLocaleString() }}<br/>
                                        VT Social NGN: {{ (parseInt(stats['total_ngn'])).toLocaleString() }}<br/>
                                        VT Social KSh: {{ (parseInt(stats['total_shilling'])).toLocaleString() }}<br/>
                                        VT Social $: {{ (parseInt(stats['total_usd'])).toLocaleString() }}<br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{walletsData['total_wallets'].toLocaleString()}} Wallets</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchWallets" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th v-for="field in userFields('mini')" :key="field" scope="col">
                                    user: {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allWallets" :key="item.id">
                                <td v-for="field in tableHeadings" :key="field">
                                    <template v-if="field == 'status'">
                                        <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                        <span v-else-if="item[field] == 'new'" class="badge badge-warning">{{ item[field] }}</span>
                                        <span v-else class="badge badge-default">{{ item[field] }}</span>
                                    </template>
                                    <template v-else-if="field == 'country'">
                                        <span class="badge badge-info">{{ item[field] }}</span>
                                    </template>
                                    <template v-else>
                                        {{ item[field] }}
                                    </template>
                                </td>
                                <td v-for="field in userFields('mini')" :key="field">
                                    <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/job/'+item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        <form id="order_by_form">
                            <select onchange="$('#order_by_form').submit()" class="form-control-old bg-primary text-white border-primary" name="order_by" id="order_by">
                                <option value="amount">order by amount</option>
                                <option value="ngn">order by ngn</option>
                                <option value="usd">order by usd</option>
                                <option value="cedi">order by cedi</option>
                                <option value="shilling">order by shilling</option>
                                <option value="created_at">order by created_at</option>
                            </select>
                        </form>
                    </button>
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Wallets",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allWalletsData: [],
        allWallets: [],
        fields: [],
        page: 0,
        walletsData: [],
        stats: [],
        order_by: 'created_at',
        total_page: 0,
        current_page: 0,
        gte_field: '',
        gte_amount: '',
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_wallets_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Wallets",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allWallets)
            this.exporting = false
        },
        async getWallets() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetWallets(this.page, this.search, this.order_by, this.gte_field, this.gte_amount)
                if (apiResponse['status'] == 'success') {
                    this.walletsData = apiResponse['data']
                    if(apiResponse['stats'] && apiResponse['stats'].length > 0){
                        this.stats = apiResponse['stats'][0]
                    }
                    this.allWallets = this.walletsData['wallets']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchWallets() {
            this.page = 0
            this.getWallets()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getWallets()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.walletsData['total_page']) {
                this.page = this.page + 1
                this.getWallets()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getWallets();
        },
        getWalletsByAmount(){
            if(!this.gte_field){
                this.notifyUser('warning', 'gte_field is required')
                return
            }
            if(!this.gte_amount){
                this.notifyUser('warning', 'gte_amount is required')
                return
            }
            this.getWallets()
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "amount",
                "grant_amount",
                "usd",
                "ngn",
                "cedi",
                "shilling",
                "country",
                "user_id",
                "user_key",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.order_by = this.getQueryParam('order_by') || 'created_at'
        $('#order_by').val(this.order_by)
        this.getWallets()
    },
    watch: {}
}
</script>

<style scoped>
</style>
