<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div v-if="forum" class="row p-3">
                <div class="mb-4">
                    <a
                        :href="vtApp()+'/dashboard/forum/'+forum.id"
                        target="_blank"
                        class="btn btn-sm btn-icon btn-info mr-3 ml-2"
                        type="button"
                        >
                        View in Viraltrend
                    </a>
                    <button
                        v-if="forum.status == 'pending' || forum.status == 'deactive'"
                        @click="approvePost"
                        class="btn btn-sm btn-icon btn-success"
                        type="button"
                        >
                        Approve
                    </button>
                    <button
                        v-if="forum.status == 'active'"
                        @click="rejectPost"
                        class="btn btn-sm btn-icon btn-danger"
                        type="button"
                        >
                        Reject
                    </button>
                    <button
                        class="btn btn-sm btn-icon btn-primary"
                        type="button"
                        >
                        Edit
                    </button>
                    <button
                        class="btn btn-sm btn-icon btn-danger"
                        type="button"
                        >
                        Delete
                    </button>
                </div>
                <div v-if="forum" class="col-xl-10 col-md-12 col-sm-12">

                    <div class="mt-2">
                        <h3 class="card-title text-uppercase text-muted mb-2">Forum Info</h3>
                        <p class="">ID: {{ forum.id }}</p>
                        <p class="">User ID: {{ forum.user_id }}</p>
                        <p class="" v-if="forum.user">Posted By: {{ forum.user.name }}</p>
                        <p class="">Title: {{ forum.title }}</p>
                        <p class="">Content: {{ forum.content }}</p>
                        <p class="">Email: {{ forum.email }}</p>
                        <p class="">Category: {{ forum.category }}</p>
                        <p class="">Image: </p>
                        <div>
                            <img style="height: 50px" :src="forum.image" />
                        </div>
                        <p class="">Total Comments: {{ forum.total_comments }}</p>
                        <p class="">Page views: {{ forum.unique_page_views }}</p>
                        <p class="">Status: {{ forum.status }}</p>
                    </div>
                </div>
                <div class="col-sm-12">
                    <h3 class="card-title text-uppercase text-muted mb-2">Comments</h3>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th
                                    v-for="field in commentFields()"
                                    :key="field"
                                    scope="col"
                                    >
                                    {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th>Posted By</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in forum.comments">
                                    <tr :key="item.id" class="commentColor">
                                        <td
                                        v-for="field in commentFields()"
                                        :key="field"
                                        >
                                        {{ item[field] }}
                                        </td>
                                        <td>
                                            <a :href="'/dashboard/user/'+item['user_id']">{{ item['user']['name'] }}</a>
                                        </td>
                                        <td>
                                            <button @click="deleteComment(item['id'])" class="btn btn-sm btn-icon btn-primary" type="button">
                                                <span class="btn-inner--icon"><i class="fa fa-window-close"></i></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="item.replies.length > 0" class="replyHeading">
                                        <td
                                            v-for="field in replyFields()"
                                            :key="field"
                                            scope="col"
                                            >
                                            {{ field.replace(/_/g, " ") }}
                                        </td>
                                        <td>Posted By</td>
                                        <td></td>
                                    </tr>
                                    <template v-for="replyItem in item.replies">
                                        <tr :key="replyItem.id" class="replyColor">
                                            <td
                                            v-for="field in replyFields()"
                                            :key="field"
                                            >
                                            {{ replyItem[field] }}
                                            </td>
                                            <td>
                                                <a :href="'/dashboard/user/'+replyItem['user_id']">{{ replyItem['user']['name'] }}</a>
                                            </td>
                                            <td>
                                                <button @click="deleteReply(replyItem['id'])" class="btn btn-sm btn-icon btn-primary" type="button">
                                                    <span class="btn-inner--icon"><i class="fa fa-window-close"></i></span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="replyItem.replies.length > 0" class="replyHeading">
                                            <td
                                                v-for="field in replyFields()"
                                                :key="field"
                                                scope="col"
                                                >
                                                {{ field.replace(/_/g, " ") }}
                                            </td>
                                            <td>Posted By</td>
                                            <td></td>
                                        </tr>
                                        <template v-for="replyReplyItem in replyItem.replies">
                                            <tr :key="replyReplyItem.id" class="replyReplyColor">
                                                <td
                                                v-for="field in replyFields()"
                                                :key="field"
                                                >
                                                {{ replyReplyItem[field] }}
                                                </td>
                                                <td>
                                                <a :href="'/dashboard/user/'+replyReplyItem['user_id']">{{ replyReplyItem['user']['name'] }}</a>
                                                </td>
                                                <td>
                                                    <button @click="deleteReplyReply(replyReplyItem['id'])" class="btn btn-sm btn-icon btn-primary" type="button">
                                                        <span class="btn-inner--icon"><i class="fa fa-window-close"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api_service'

  export default {
    name: "User",
    components: {
    },
    data: () => ({
      selectedItem: null,
      id: "",
      message: "",
      loading: "",
      forum: "",
      fields: [
      ],
      withdrawal_fields: [
        "id",
        "user_id",
        "account_id",
        "amount",
        "wallet",
        "country",
        "status",
        "created_at"
      ]
    }),
    methods: {
      async getForum () {
        this.loading = true
        try {
          var apiResponse = await ApiService.GetForum(this.id)
          if(apiResponse['status'] == 'success'){
            this.forum = apiResponse['data']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async approvePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.ApproveForum(this.id)
        this.notifyUser(apiResponse['status'],apiResponse['message'])
          if(apiResponse['status'] == 'success'){
              location.href = ''
          }else{
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
        } finally {
          this.loading = false
        }
      },
      async rejectPost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.RejectForum(this.id)
        this.notifyUser(apiResponse['status'],apiResponse['message'])
          if(apiResponse['status'] == 'success'){
              location.href = ''
          }else{
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
        } finally {
          this.loading = false
        }
      },
      async deleteReply(id) {

      },
      async deleteComment(id) {

      },
      async deleteReplyReply(id) {

      }
    },
    mounted () {
        this.id = this.$route.params['id']
        this.getForum(this.id.toString())
    }
  }
</script>

<style scoped>
    .replyHeading {
        background-color: #f6f9fc;
        text-transform: uppercase
    }
    .commentColor {
        background-color: #fcf8f6;
    }
    .replyColor {
        background-color: #f6fcf8;
    }
    .replyReplyColor {
        background-color: #fcf6fc;
    }
</style>
