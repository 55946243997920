<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="shopsData['total_page']">{{ (parseInt(shopsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{shopsData['total_shops'].toLocaleString()}} Shops</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchShops" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th v-for="field in userFields('mini')" :key="'user-'+field" scope="col">
                                    user: {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allShops" :key="item.id">
                                <td v-for="field in tableHeadings" :key="field">
                                    <template v-if="field == 'image'">
                                        <a :href="item[field]" target="_blank">
                                            <img style="height:30px" :src="item[field]" />
                                        </a>
                                    </template>
                                    <template v-else>{{ item[field] }}</template>
                                </td>
                                <td v-for="field in userFields('mini')" :key="'user-'+field">
                                    <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                </td>
                                <td class="text-right">
                                    <button v-if="item.is_approved == 'false'" @click="approveShop(item.id)" class="btn btn-sm btn-icon btn-primary" type="button">
                                        <span class="btn-inner--icon"><i class="fa fa-check"></i></span>
                                    </button>
                                    <button v-else @click="disapproveShop(item.id)" class="btn btn-sm btn-icon btn-danger" type="button">
                                        <span class="btn-inner--icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Shops",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allShopsData: [],
        allShops: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        shopsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_shops_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Shops",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allShops)
            this.exporting = false
        },
        async getShops() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetShops(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.shopsData = apiResponse['data']
                    this.allShops = this.shopsData['shops']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async approveShop(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.ApproveShop(id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async disapproveShop(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.DisapproveShop(id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchShops() {
            this.page = 0
            this.getShops()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getShops()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.shopsData['total_page']) {
                this.page = this.page + 1
                this.getShops()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getShops();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "user_id",
                "address",
                "city",
                "state",
                "country",
                'unique_views_count',
                "status",
                "image",
                "online",
                "is_approved",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getShops()
    },
    watch: {}
}
</script>

<style scoped>
</style>
