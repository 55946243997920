<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0" v-if="user.user">Name: {{ user.user.name }}</h3>
                            </div>
                            <div class="col text-right">
                            </div>
                        </div>
                    </div>
                    <LinearLoader v-if="loading"></LinearLoader>
                    <div v-if="user" class="row p-3">
                        <div class="col-sm-12">
                            <div v-if="user.referred_by" class="my-3">
                                <h3 class="card-title text-uppercase text-muted mb-2"></h3>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            Referred By:
                                            <span class="badge badge-info"> {{ user.referred_by.email }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h3 class="card-title text-uppercase text-muted mb-2">Referrals</h3>
                            <div class="table-responsive">
                                <table class="table align-items-center table-flush">
                                    <thead class="thead-light">
                                        <th v-for="field in tableHeadings" :key="'table_'+field" scope="col">
                                            {{ field.replace(/_/g, " ") }}
                                        </th>
                                        <th scope="col"></th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,key) in user.referrals" :key="key">
                                            <td v-for="field in tableHeadings" :key="field">
                                                <template v-if="field == 'id'">
                                                    <a :href="'/dashboard/user/'+item[field]">
                                                        {{ item[field] }}
                                                    </a>
                                                </template>
                                                <template v-else-if="field == 'status'">
                                                    <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                                    <span v-else-if="item[field] == 'new'" class="badge badge-warning">{{ item[field] }}</span>
                                                    <span v-else class="badge badge-default">{{ item[field] }}</span>
                                                </template>
                                                <template v-else-if="field == 'type'">
                                                    <span class="badge badge-info">{{ item[field] }}</span>
                                                </template>
                                                <template v-else>
                                                    {{ item[field] }}
                                                </template>
                                            </td>
                                            <td class="text-right">
                                                <router-link :to="'/dashboard/user/' + item.id" class="btn btn-sm btn-icon btn-primary" >
                                                    <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import ApiService from '@/services/api_service'
    
    export default {
        name: "UserReferrals",
        components: {},
        data: () => ({
            selectedItem: null,
            id: "",
            message: "",
            loading: "",
            user: "",
            social: "",
            account: "",
            subscription: "",
            fee: "",
            amount_credit: "",
            amount_debit: "",
            currency: "NGN",
            currentUser: '',
            fields: [],
            withdrawalsData: [],
            withdrawal_fields: [
                "id",
                "user_id",
                "account_id",
                "amount",
                "wallet",
                "country",
                "status",
                "created_at"
            ]
        }),
        methods: {
            async getCurrentUser() {
                this.loading = true
                try {
                    var apiResponse = await ApiService.GetUser(this.id)
                    if (apiResponse['status'] == 'success') {
                        this.user = apiResponse['data']
                        this.withdrawalsData = apiResponse["data"];
                        if(this.withdrawalsData["fee"] && this.withdrawalsData["fee"]['value']){
                            this.fee = this.withdrawalsData["fee"]['value'];
                        }
                        this.social = apiResponse['data']['social']
                        this.account = apiResponse['data']['account']
                        this.subscription = apiResponse['data']['subscription']
                        if(this.user.user.country){
                            if(this.user.user.country.toLowerCase() == 'ghana'){
                                this.currency = 'Cedi'
                            }else if(this.user.user.country.toLowerCase() == 'kenya'){
                                this.currency = 'Shilling'
                            }
                        }
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            },
        },
        mounted() {
            this.currentUser = this.getUser()
            this.id = this.$route.params['id']
            this.getCurrentUser(this.id.toString())
        },
        computed: {
            tableHeadings() {
                return [
                    "id",
                    "name",
                    "username",
                    "email",
                    "type",
                    "state",
                    "country",
                    "gender",
                    "dob",
                    "status",
                    "created_at",
                ];
            },
        },
    }
    </script>
    
    <style scoped>
    </style>
    