<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col flex">
                            <h3 v-if="!loading" class="mb-0">
                                Total: {{ total_feeds.toLocaleString() }}
                            </h3>
                            <h5 v-if="!loading" class="mb-0 text-capitalize">
                                Section: 
                                <template v-if="section">{{ section.replace('_',' ') }}</template>
                                <template v-else>All</template>
                            </h5>
                            <div>
                                <p v-if="feedsData['total_page']">{{ (parseInt(feedsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                            </div>
                            <div class="">
                                <button @click="changeSection('')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    All
                                </button>
                                <button @click="changeSection('admin_pinned')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    Admin Pinned
                                </button>
                                <button @click="changeSection('user_pinned')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    User Pinned
                                </button>
                                <button @click="changeSection('can_donate')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Can Donate
                                </button>
                                <button @click="changeSection('delisted')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Delisted
                                </button>
                            </div>
                        </div>
                        <div class="col px-3">
                            <div v-if="current_donation">
                                <span class="badge badge-success">{{ current_donation.id }}</span>
                            </div>
                            <div v-if="show_end_date_time == 'true'">
                                <div class="mb-2" >
                                    <label>Donation End Date</label>
                                    <input class="form-control input-sm mb-2" type="date" name="donation_end_date" v-model="end_date_time" />
                                    <button @click="manageDonation('end_date_time', current_donation.id)" class="btn btn-sm btn-icon btn-primary" :data-loading="loading" :disabled="loading">
                                        <i class="fa fa-send"></i>save
                                    </button>
                                    <button @click="hideEndDateForm" type="button" class="btn btn-sm btn-icon btn-danger ml-2" :data-loading="loading" :disabled="loading">
                                        <i class="fa fa-close"></i>close
                                    </button>
                                </div>
                            </div>
                            <div v-if="show_target_amount == 'true'">
                                <div class="mb-2" >
                                    <label>Donation Target Amount(USD)</label>
                                    <input class="form-control input-sm mb-2" placeholder="" type="phone" name="donation_target" v-model="target_amount" />
                                    <button @click="manageDonation('target_amount', current_donation.id)" class="btn btn-sm btn-icon btn-primary" :data-loading="loading" :disabled="loading">
                                        <i class="fa fa-send"></i>save
                                    </button>
                                    <button @click="hideTargetAmountForm" type="button" class="btn btn-sm btn-icon btn-danger ml-2" :data-loading="loading" :disabled="loading">
                                        <i class="fa fa-close"></i>close
                                    </button>
                                </div>
                            </div>
                            <div class=" d-flex">
                                <input v-model="search" class="form-control" placeholder="type and click the search button" />
                                <button @click="searchFeeds" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                    <i class="fa fa-send"></i>Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <th scope="col">ID</th>
                            <th scope="col">User Email</th>
                            <th scope="col">VT Name</th>
                            <th scope="col">Is User Pinned</th>
                            <th scope="col">Is Admin Pinned</th>
                            <th scope="col">Can Donate</th>
                            <th scope="col">Donation Target(NGN)</th>
                            <th scope="col">Donation End Date</th>
                            <th scope="col">Amount Raised(USD)</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item,key) in allFeeds" :key="'item-'+key">
                                <td>
                                    {{ item['id'] }}
                                </td>
                                <td>
                                    <a :href="'/dashboard/user/'+item['user']['id']">
                                        {{ item['user']['email'] }}
                                    </a>
                                </td>
                                <td>
                                    <a :href="'/dashboard/user/'+item['user']['id']">
                                        {{ item['user']['name'] }}
                                    </a>
                                </td>
                                <td>
                                    {{ item['user_pin'] }}
                                </td>
                                <td>
                                    {{ item['ad_pin'] }}
                                </td>
                                <td>
                                    <span v-if="item['allow_donation'] == 'true'" class="badge badge-success">true</span>
                                    <span v-else class="badge badge-warning">false</span>
                                </td>
                                <td>
                                    <template v-if="parseInt(item['donation_target']) > 0">{{ parseFloat(item['donation_target']).toLocaleString() }}</template>
                                    <template v-else>{{ item['donation_target'] }}</template>
                                </td>
                                <td>
                                    {{ item['donation_end_date'] }}
                                </td>
                                <td>
                                    {{ calculateSum(item['donations'], 'amount_usd').toFixed(2).toLocaleString() }}
                                </td>
                                <td>
                                    {{ item['created_at'] }}
                                    <p>
                                        <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'active'" class="badge badge-success">{{ item['status'] }}</span>
                                        <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                    </p>
                                </td>
                                <td class="text-right">
                                    <div>
                                        <p>
                                            No of Reports: {{item.no_of_reports}}
                                            <br/>
                                            No of User Blocks: {{item.no_of_blocks}}
                                        </p>
                                    </div>
                                    <a :href="vtSocial()+'/dashboard/feed/'+item.id" target="_blank" class="btn btn-primary btn-sm">
                                        <i class="fa fa-send"></i>view
                                    </a>
                                    <template v-if="item.allow_donation == 'true'">
                                        <button @click="showEndDateForm(item)" class="btn btn-sm btn-icon btn-default" type="button" >
                                            Set End Date
                                        </button>
                                        <button @click="showTargetAmountForm(item)" class="btn btn-sm btn-icon btn-default" type="button" >
                                            Set Target Amount
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button @click="manageDonation('enable', item.id)" class="btn btn-sm btn-icon btn-success" type="button" >
                                            Enable Donation
                                        </button>
                                    </template>
                                    <template v-if="item.delisted == 'true'">
                                        <button @click="manageFeedList('relist', item.id)" class="btn btn-sm btn-icon btn-success" type="button" >
                                            Relist
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button @click="manageFeedList('delist', item.id)" class="btn btn-sm btn-icon btn-danger" type="button" >
                                            Delist
                                        </button>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv";
import ApiService from "@/services/api_service";

export default {
    name: "Feeds",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        section: "",
        total_feeds: 0,
        total_feed_amount: 0,
        active_feed_amount: 0,
        active_feeds: 0,
        searchResult: [],
        allFeedsData: [],
        allFeeds: [],
        fields: [],
        page: 0,
        feedsData: [],
        fee: 0,
        total_page: 0,
        current_page: 0,
        target_amount: '',
        end_date_time: '',
        current_donation: '',
        show_target_amount: '',
        show_end_date_time: '',
    }),
    methods: {
        exportToCSV() {
            this.exporting = true;
            const options = {
                filename: `viraltrend_feeds_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Feeds",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.allFeeds);
            this.exporting = false;
        },
        async getFeeds() {
            this.loading = true;
            try {
                var apiResponse = await ApiService.GetFeeds(
                    this.page,
                    this.per_page,
                    this.section,
                    this.search
                );
                if (apiResponse["status"] == "success") {
                    this.feedsData = apiResponse["data"];
                    this.allFeeds = this.feedsData["feeds"];
                    this.total_page = this.feedsData["total_page"];
                    this.current_page = parseInt(this.feedsData["current_page"]);
                    if (this.page < 2) {
                        this.total_feeds = this.feedsData["total_feeds"];
                    }
                    if (this.current_page + 10 <= this.total_page) {
                        this.total_page = this.current_page + 10;
                    }
                } else {
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async searchFeeds() {
            this.page = 0;
            this.getFeeds();
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1;
                this.getFeeds();
            } else {
                this.notifyUser("warning", "This is the first page");
            }
        },
        async nextPage() {
            if (this.page < parseInt(this.feedsData["total_page"]) - 1) {
                this.page = this.page + 1;
                this.getFeeds();
            } else {
                this.notifyUser("warning", "This is the last page");
            }
        },
        async openPage(a) {
            this.page = a;
            this.getFeeds();
        },
        async changeSection(a) {
            this.section = a
            this.page = 0;
            this.getFeeds();
        },
        async manageDonation(action, id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.ManageFeedDonation(action, this.target_amount, this.end_date_time, id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    this.getFeeds()
                } else {
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async manageFeedList(action, id) {
            let proceed = confirm("Are you sure you want to "+action+" this feed?");
            if (proceed) {
                this.loading = true;
                try {
                    var apiResponse = await ApiService.ManageFeedList(action, id);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        this.getFeeds()
                    } else {
                    }
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                }
            }
        },
        setCurrentDonation(a){
            this.current_donation = a
            this.target_amount = a.donation_target
            this.end_date_time = a.donation_end_date
        },
        showEndDateForm(a){
            this.setCurrentDonation(a)
            this.show_end_date_time = 'true'
        },
        showTargetAmountForm(a){
            this.setCurrentDonation(a)
            this.show_target_amount = 'true'
        },
        hideEndDateForm(a){
            this.setCurrentDonation('')
            this.show_end_date_time = 'false'
        },
        hideTargetAmountForm(a){
            this.setCurrentDonation('')
            this.show_target_amount = 'false'
        }
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "status",
                "created_at",
                "updated_at",
            ];
        },
    },
    mounted() {
        this.search = ''
        this.getFeeds();
    },
    watch: {},
};
</script>

<style scoped></style>
