<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 class="mb-0">Create Post Ad</h3>
                            <a href="/dashboard/coupon-codes" class="ml-4 btn btn-success text-white">View Coupon Codes</a>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="card-header col-sm-8 m-auto">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="">Type</label>
                                <select v-model="type" required="required" id="" class="form-control populate  viral-select">
                                    <option value="" selected="selected">- Select a type -</option> 
                                    <option value="all_users">All Users</option>
                                    <option value="single_user">Single User</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="type == 'single_user'">
                            <div class="form-group">
                                <label class="form-control-label" for="owner_id">User ID</label>
                                <input v-model="owner_id" type="text" id="owner_id" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="amount">Amount</label>
                                <input v-model="amount" type="text" id="amount" class="form-control" required>
                            </div>
                        </div>
                    </div>
                    <div>

                        <button @click="createCouponCode" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "CreateCouponCodes",
    components: {},
    data: () => ({
        loading: false,
        type: '',
        amount: '',
        owner_id: '',
    }),
    methods: {
        async createCouponCode() {
            this.loading = true
            try {
                var apiResponse = await ApiService.CreateCouponCode(this.type, this.amount, this.owner_id)
                if (apiResponse['status'] == 'success') {
                    this.type = ''
                    this.amount = ''
                    this.owner_id = ''
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
    },
    computed: {
        tableHeadings() {
            return []
        }
    },
    mounted() {},
    watch: {}
}
</script>

<style scoped>
    </style>
