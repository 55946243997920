import moment from "moment";
import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};


Vue.use(Toast, options);

export default {
    methods: {
        defaultImageUrl: function() {
            return '/assets/img/avatar.png'
        },
        defaultPostUrl: function() {
            return '/assets/img/vt-logo.jpg'
        },
        timeAgo: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " y";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " m";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " d";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " Hrs";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " Mins";
            }
            return Math.floor(seconds) + " Secs";
        },
        unixToUtc: function(a) {
            let b = a
            if (b) {
                b = moment.unix(b / 1000).format("DD-MM-YYYY HH:mm:ss");
            }
            return b
        },
        daysLater: function(d) {

            if (!d) {
                return ''
            }
            let todaysDate = new Date()
            let date = new Date(d)

            let todaysDateDay = todaysDate.getDate()
            let dobDateDay = date.getDate()

            let daysLeft = dobDateDay - todaysDateDay

            return daysLeft
        },
        ageCalculator: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            return Math.floor(interval);
        },
        stripTags: function(a) {
            let b = a.replace(/(<([^>]+)>)/gi, "");
            return b
        },
        getOddEven: function(a) {
            let b = 'odd'
            if (a) {
                let c = parseInt(a / 10)
                let d = c % 2
                if (d == 0) {
                    b = 'even'
                }
            }
            return b
        },
        formatDate: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY')
            }
        },
        formatDateTime: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY HH:mm:ss')
            }
        },
        formatAmount: function(a) {
            if (a) {
                a = Number(a).toLocaleString('en-US')
            }
            return a
        },
        formatDateForum: function(a) {
            if (a) {
                return moment(String(a)).format('MMM Do, YYYY')
            }
        },
        toggleReplyForm(id) {
            // $("#" + id).toggle()
            let a = $("#" + id).css('display')
            if (a == 'none') {
                // hide all other sections
                this.hideAllSections()
                $("#" + id).show()
            } else {
                $("#" + id).hide()
            }
        },
        toggleProgress(a) {
            if (a == 'show') {
                $("#progressLoader").show()
            } else if (a == 'hide') {
                $("#progressLoader").hide()
            } else {
                $("#progressLoader").toggle()
            }
        },
        toggleCommentSection() {
            let a = $(".commentSection").css('display')
            console.log('---', a)
            if (a == 'none') {
                // hide all other sections
                this.hideAllSections()
                $(".commentSection").show()
            } else {
                $(".commentSection").hide()
            }
        },
        hideAllSections() {
            console.log(';:::::::::', 'hideAllSections')
            $(".commentSection").hide()
            $(".replySection").hide()
            $(".replyReplySection").hide()
        },
        videoDuration(a) {
            let b = '0:00'
            if (!a || a == 0) {
                return b
            }
            a = parseInt(a)
            if (a > 59) {
                let c = parseInt(a / 60)
                let secs = a % 60
                let mins = 0
                let hrs = 0
                if (c > 59) {
                    hrs = c / 60
                    mins = c % 60
                    b = hrs + ':' + mins + ':' + secs
                } else {
                    mins = c
                    b = mins + ':' + secs
                }
            } else {
                b = '0:' + a.toString()
            }
            console.log('b', b)
            return b
        },
        pullJobtags(t) {
            let a = ''
            if (t) {
                let b = JSON.parse(t)
                for (let i = 0; i < b.length; i++) {
                    const c = b[i];
                    if (c) {
                        a = a + '<a class="border px-3 py-1.5 rounded-md text-sm inline-block"> ' + c + ' </a>'
                    }
                }
            }
            return a;
        },
        saveUserAndToken(user) {
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('access_token', user['remember_token'])
        },
        getUser() {
            let userObj = {}
            let user = localStorage.getItem('user')
            if (user) {
                if ((typeof user).toLowerCase() == 'string') {
                    userObj = JSON.parse(user)
                } else {
                    userObj = user
                }
            }
            return userObj
        },
        getAccessToken() {
            let access_token = localStorage.getItem('access_token')
            return access_token
        },
        commentFields() {
            let a = [
                "id",
                "comment",
                "created_at"
            ]
            return a
        },
        replyFields() {
            let a = [
                "id",
                "reply",
                "created_at"
            ]
            return a
        },
        jobCompanyFields(type) {
            let a = [
                "id",
                "user_id",
                "name",
                "location",
                "website",
                "sector",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name",
                    "location",
                    "website",
                    "sector",
                ]
            }
            return a
        },
        jobRoleFields(type) {
            let a = [
                "id",
                "user_id",
                "name",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name"
                ]
            }
            return a
        },
        jobTypeFields(type) {
            let a = [
                "id",
                "user_id",
                "name",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name"
                ]
            }
            return a
        },
        userFields(type) {
            let a = [
                "id",
                "name",
                "username",
                "dob",
                "phone",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name"
                ]
            }
            return a
        },
        shopFields(type) {
            let a = [
                "id",
                "name",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name"
                ]
            }
            return a
        },
        accountFields(type) {
            let a = [
                "id",
                "user_id",
                "name",
                "bank",
                "account_no",
                "account_type",
                "status",
                "created_at",
                "updated_at"
            ]
            if (type == 'mini') {
                a = [
                    "name",
                    "bank",
                    "account_no",
                    "account_type"
                ]
            }
            return a
        },
        vtApp() {
            let a = 'https://promoters.vitisocial.com'
            if (location.href.search('localhost') > -1 || location.href.search('127.0.0.1') > -1) {
                a = 'http://localhost:8080'
            }
            if (location.href.search('test.') > -1) {
                a = 'https://promotersdev.vitisocial.com'
            }

            return a
        },
        vtSocial() {
            let a = 'https://vitisocial.com'
            if (location.href.search('localhost') > -1 || location.href.search('127.0.0.1') > -1) {
                a = 'http://localhost:8080'
            }
            if (location.href.search('test.') > -1) {
                a = 'https://test.vitisocial.com'
            }

            return a
        },
        calculateSum(data, field){
            let total = 0
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if(element[field]){
                    total = total+parseFloat(element[field])
                }
            }
            return total
        },
        replaceAll(a, b, c) {
            return a.split(b).join(c);
        },
        getQueryParam(param){
            let a = ''
            var urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has(param)){
                a = urlParams.get(param)
            }
            return a
        },
        currencySymbol: function(a) {
            let b = ''
            if(!a){
                b = '$';
            }else{
                switch (a.toUpperCase()) {
                    case 'USD':
                        b = '$'
                        break;

                    case 'NGN':
                        b = '₦'
                        break;

                    case 'EUR':
                        b = '€'
                        break;

                    case 'POUNDS':
                        b = '£'
                        break;

                    case 'CEDI':
                        b = 'GH₵'
                        break;

                    case 'SHILLING':
                        b = 'Ksh'
                        break;

                    case 'GHANA':
                        b = 'GH₵'
                        break;

                    case 'KENYA':
                        b = 'Ksh'
                        break;

                    case 'USA':
                        b = '$'
                        break;

                    case 'US':
                        b = '$'
                        break;

                    case 'NIGERIA':
                        b = '₦'
                        break;

                    default:
                        b = '$'
                        break;
                }
            }

            return b
        },
        add(a, b){
            let c = '0'
            if(a && b){
                c = (parseFloat(a)+parseFloat(b))
            }
            return c
        },
        subtract(a, b, c){
            let d = '0'
            if(a && b){
                d = (parseFloat(a)-parseFloat(b))
            }
            if(c){
                d = d-parseFloat(c)
            }
            return d
        },
        logout() {
            localStorage.clear()
        },
        notifyUser(status, message) {
            if (status == 'success') {
                this.$toast.success(message)
            } else if (status == 'warning') {
                this.$toast.warning(message)
            } else {
                this.$toast.error(message)
            }
            if (location.href.search('dashboard') > -1) {
                if (message && message.search('invalid token') > -1) {
                    location.href = '/login'
                }
            }

            return true;
        }
    },
}