<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" v-if="user.user">Name: {{ user.user.name }}</h3>
                        </div>
                        <div class="col text-right">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div v-if="user" class="row p-3">
                    <div class="col-sm-12">
                        <h3 class="card-title text-uppercase text-muted mb-2">Withdrawals</h3>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <th scope="col">User Email</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">VT Name</th>
                                    <th scope="col">Bank Details</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Request Date</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Action</th>
                                </thead>
                                <tbody>
                                    <tr v-for="item in user.withdrawals" :key="item.id">
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['email'] }}</span>
                                        </td>
                                        <td>
                                            {{ parseFloat(item['amount']).toLocaleString(2) }}
                                            <p>{{ currency }} {{ calculateAmountToPay(item['amount'], fee) }}</p>
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['name'] }}</span><br />
                                            <span v-if="item['account']">{{ item['account']['account_no'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['bank'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['account_type'] }}</span>
                                        </td>
                                        <td>
                                            {{ item['created_at'] }}
                                            <p>
                                                <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                                <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                                <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                            </p>
                                            {{ item['updated_at'] }}<br />
                                            {{ item['updated_by'] }}
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                        </td>
                                        <td class="text-right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
import ApiService from '@/services/api_service'

export default {
    name: "UserWithdrawals",
    components: {},
    data: () => ({
        selectedItem: null,
        id: "",
        message: "",
        loading: "",
        user: "",
        social: "",
        account: "",
        subscription: "",
        fee: "",
        amount_credit: "",
        amount_debit: "",
        currency: "NGN",
        currentUser: '',
        fields: [],
        withdrawalsData: [],
        withdrawal_fields: [
            "id",
            "user_id",
            "account_id",
            "amount",
            "wallet",
            "country",
            "status",
            "created_at"
        ]
    }),
    methods: {
        async getCurrentUser() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetUser(this.id)
                if (apiResponse['status'] == 'success') {
                    this.user = apiResponse['data']
                    this.withdrawalsData = apiResponse["data"];
                    if (this.withdrawalsData["fee"] && this.withdrawalsData["fee"]['value']) {
                        this.fee = this.withdrawalsData["fee"]['value'];
                    }
                    this.social = apiResponse['data']['social']
                    this.account = apiResponse['data']['account']
                    this.subscription = apiResponse['data']['subscription']
                    if (this.user.user.country) {
                        if (this.user.user.country.toLowerCase() == 'ghana') {
                            this.currency = 'Cedi'
                        } else if (this.user.user.country.toLowerCase() == 'kenya') {
                            this.currency = 'Shilling'
                        }
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        calculateAmountToPay(a, b) {
            let c = 0
            a = parseFloat(a)
            b = parseFloat(b)
            if (a > 0 && b > 0) {
                let d = ((100 - b) / 100) * a
                c = d.toLocaleString(2)
            }
            return c
        },
    },
    mounted() {
        this.currentUser = this.getUser()
        this.id = this.$route.params['id']
        this.getCurrentUser(this.id.toString())
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "username",
                "email",
                "type",
                "state",
                "country",
                "gender",
                "dob",
                "status",
                "created_at",
            ];
        },
    },
}
</script>
    
    
<style scoped>
    </style>
