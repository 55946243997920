import Vue from "vue"
import VueRouter from "vue-router"
import Login from "../views/auth/Login"
import Layout from "../views/dashboard/layout/Layout"
import Overview from "../views/dashboard/pages/Overview"
import Users from '@/views/dashboard/pages/Users'
import User from '@/views/dashboard/pages/User'
import UserWithdrawals from '@/views/dashboard/pages/UserWithdrawals'
import UserMultipleEarnings from '@/views/dashboard/pages/UserMultipleEarnings'
import UserReferrals from '@/views/dashboard/pages/UserReferrals'
import UserVitisocialWithdrawals from '@/views/dashboard/pages/UserVitisocialWithdrawals'
import Jobs from '@/views/dashboard/pages/Jobs'
import Job from '@/views/dashboard/pages/Job'
import Blogs from '@/views/dashboard/pages/Blogs'
import Blog from '@/views/dashboard/pages/Blog'
import Forums from '@/views/dashboard/pages/Forums'
import Forum from '@/views/dashboard/pages/Forum'
import Wallets from '@/views/dashboard/pages/Wallets'
import Subscriptions from '@/views/dashboard/pages/Subscriptions'
import Transactions from '@/views/dashboard/pages/Transactions' 
import Withdrawals from '@/views/dashboard/pages/Withdrawals'
import SocialWithdrawals from '@/views/dashboard/pages/SocialWithdrawals'
import Adverts from '@/views/dashboard/pages/Adverts'
import CreateAdvert from '@/views/dashboard/pages/AddPages/CreateAdvert'
import Advert from '@/views/dashboard/pages/Advert'
import Notifications from '@/views/dashboard/pages/Notifications'
import Posts from '@/views/dashboard/pages/Posts'
import Post from '@/views/dashboard/pages/Post'
import PostAds from '@/views/dashboard/pages/PostAds'
import CreatePostAds from '@/views/dashboard/pages/AddPages/CreatePostAds'
import Settings from '@/views/dashboard/pages/Settings'
import Search from '@/views/dashboard/pages/Search'
import CreateSetting from '@/views/dashboard/pages/AddPages/CreateSetting'
import Accounts from '@/views/dashboard/pages/Accounts'
import Products from '@/views/dashboard/pages/Products'
import ProductsExtra from '@/views/dashboard/pages/ProductsExtra'
import Events from '@/views/dashboard/pages/Events'
import Shops from '@/views/dashboard/pages/Shops'
import Orders from '@/views/dashboard/pages/Orders'
import Order from '@/views/dashboard/pages/Order'
import Courses from '@/views/dashboard/pages/Courses'
import UserVerificationApplications from '@/views/dashboard/pages/UserVerificationApplications'
import Reports from '@/views/dashboard/pages/Reports'
import CreateCouponCode from '@/views/dashboard/pages/AddPages/CreateCouponCode'
import CouponCodes from '@/views/dashboard/pages/CouponCodes'
import UserTask from '@/views/dashboard/pages/UserTask'
import Feeds from '@/views/dashboard/pages/Feeds'
import CreateNoty from '@/views/dashboard/pages/AddPages/CreateNoty'
import Admins from '@/views/dashboard/pages/Admins'
import Groups from '@/views/dashboard/pages/Groups'
import Pages from '@/views/dashboard/pages/Pages'
import UserStats from '@/views/dashboard/pages/UserStats'
import FinanceOverview from '@/views/dashboard/pages/FinanceOverview'
import AdminWalletLogs from '@/views/dashboard/pages/AdminWalletLogs'
import PageNotFound from "../views/auth/PageNotFound"

import * as guards from "./guards"

Vue.use(VueRouter)

const routes = [{
        path: "/",
        name: "home",
        // beforeEnter: guards.isNotAuthenticated,
        component: Login
    },
    {
        path: "/login",
        name: "login",
        // beforeEnter: guards.isNotAuthenticated,
        component: Login
    },
    {
        path: "/dashboard",
        component: Layout,
        beforeEnter: guards.isAuthenticated,
        children: [{
                path: "",
                name: "dashboard",
                component: Overview
            },
            {
                path: "overview",
                name: "overview",
                component: Overview
            },
            {
                path: "finance-overview",
                name: "finance-overview",
                component: FinanceOverview
            },
            {
                path: "users",
                name: "users",
                component: Users
            },
            {
                path: "user/:id",
                name: "user",
                component: User
            },
            {
                path: "user/withdrawals/:id",
                name: "user-withdrawals",
                component: UserWithdrawals
            },
            {
                path: "user/vitisocial-withdrawals/:id",
                name: "user-vitisocial-withdrawals",
                component: UserVitisocialWithdrawals
            },
            {
                path: "user/referrals/:id",
                name: "user-referrals",
                component: UserReferrals
            },
            {
                path: "user/multiple-earnings/:id",
                name: "user-multiple-earnings",
                component: UserMultipleEarnings
            },
            {
                path: "user-tasks/:type/:user_id",
                name: "user-tasks",
                component: UserTask
            },
            {
                path: "feeds",
                name: "feeds",
                component: Feeds
            },
            {
                path: "jobs",
                name: "jobs",
                component: Jobs
            },
            {
                path: "job/:id",
                name: "job",
                component: Job
            },
            {
                path: "blogs",
                name: "blogs",
                component: Blogs
            },
            {
                path: "blog/:id",
                name: "blog",
                component: Blog
            },
            {
                path: "forums",
                name: "forums",
                component: Forums
            },
            {
                path: "forum/:id",
                name: "forum",
                component: Forum
            },
            {
                path: "wallets",
                name: "wallets",
                component: Wallets
            },
            {
                path: "subscriptions",
                name: "subscriptions",
                component: Subscriptions
            },
            {
                path: "transactions",
                name: "transactions",
                component: Transactions
            },
            {
                path: "withdrawals",
                name: "withdrawals",
                component: Withdrawals
            },
            {
                path: "vitisocial-withdrawals",
                name: "vitisocial-withdrawals",
                component: SocialWithdrawals
            },
            {
                path: "adverts",
                name: "adverts",
                component: Adverts
            },
            {
                path: "advert/:id",
                name: "advert",
                component: Advert
            },
            {
                path: "create-advert",
                name: "create-advert",
                component: CreateAdvert
            },
            {
                path: "notifications",
                name: "notifications",
                component: Notifications
            },
            {
                path: "create-notification",
                name: "create-notification",
                component: CreateNoty
            },
            {
                path: "posts",
                name: "posts",
                component: Posts
            },
            {
                path: "post/:id",
                name: "post",
                component: Post
            },
            {
                path: "post-ads",
                name: "post-ads",
                component: PostAds
            },
            {
                path: "create-post-ad",
                name: "create-post-ad",
                component: CreatePostAds
            },
            {
                path: "settings",
                name: "settings",
                component: Settings
            },
            {
                path: "search",
                name: "search",
                component: Search
            },
            {
                path: "create-setting",
                name: "create-setting",
                component: CreateSetting
            },
            {
                path: "accounts",
                name: "accounts",
                component: Accounts
            },
            {
                path: "events",
                name: "events",
                component: Events
            },
            {
                path: "products",
                name: "products",
                component: Products
            },
            {
                path: "shops",
                name: "shops",
                component: Shops
            },
            {
                path: "orders",
                name: "orders",
                component: Orders
            },
            {
                path: "order/:id",
                name: "order",
                component: Order
            },
            {
                path: "products-extra-info",
                name: "products-extra-info",
                component: ProductsExtra
            },
            {
                path: "courses",
                name: "courses",
                component: Courses
            },
            {
                path: "coupon-codes",
                name: "coupon-codes",
                component: CouponCodes
            },
            {
                path: "create-coupon-code",
                name: "create-coupon-code",
                component: CreateCouponCode
            },
            {
                path: "verification-applications",
                name: "verification-applications",
                component: UserVerificationApplications
            },
            {
                path: "reports",
                name: "reports",
                component: Reports
            },
            {
                path: "groups",
                name: "groups",
                component: Groups
            },
            {
                path: "pages",
                name: "pages",
                component: Pages
            },
            {
                path: "user-stats",
                name: "user-stats",
                component: UserStats
            },
            {
                path: "admins",
                name: "admins",
                component: Admins
            },
            {
                path: "admin-wallet-logs",
                name: "admin-wallet-logs",
                component: AdminWalletLogs
            },
        ]
    },
    { path: "*", component: PageNotFound }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes
})

export default router