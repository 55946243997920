<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 class="mb-0">Create Post Ad</h3>
                            <a href="/dashboard/post-ads" class="ml-4 btn btn-success text-white">View Post Ad</a>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="card-header col-sm-8 m-auto">

                    <div>
                        <h4>Add Normal Ad Code</h4>
                    </div>

                    <div class="row">
                        <div v-for="field in fields" :key="field.name" class="col-lg-12">
                            <div v-if="field.type === 'file'" class="form-group">
                                <label class="form-control-label">Kindly ensure your banner image is 780x130</label>
                                <div>
                                    <img v-if="data[field.name]" :src="data[field.name]" alt="" class="mb-2 img-fluid img-thumbnail">
                                    <input hidden type="file" :ref="field.name" @change="uploadFile(field.name, $event)" :accept="field.accept">
                                    <button @click="$refs[field.name][0].click()" class="btn btn-sm btn-block btn-primary p-3" :data-loading="loading">
                                        Upload {{ field.name.replace(/_/g, " ") }}
                                    </button>
                                </div>
                            </div>
                            <div v-else class="form-group">
                                <label class="form-control-label" :for="field.name">{{ field.name.replace(/_/g, " ") }}</label>
                                <input v-model="data[field.name]" type="text" :id="field.name" class="form-control" required>
                            </div>
                        </div>
                    </div>
                    <div>

                        <button @click="createPostAd" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Post Advert
                        </button>
                    </div>
                </div>

                <div class="card-header col-sm-8 m-auto">

                    <div>
                        <h4>Add Google Ad Code</h4>
                    </div>

                    <div class="row">
                        <div v-for="field in fields_google" :key="field.name" class="col-lg-12">
                            <div v-if="field.type === 'file'" class="form-group">
                                <label class="form-control-label"></label>
                                <div>
                                    <img v-if="new_data[field.name]" :src="new_data[field.name]" alt="" class="mb-2 img-fluid img-thumbnail">
                                    <input hidden type="file" :ref="field.name" @change="uploadFile(field.name, $event)" :accept="field.accept">
                                    <button @click="$refs[field.name][0].click()" class="btn btn-sm btn-block btn-primary" :data-loading="loading">
                                        Upload {{ field.name.replace(/_/g, " ") }}
                                    </button>
                                </div>
                            </div>
                            <div v-else class="form-group">
                                <label class="form-control-label" :for="field.name">{{ field.name.replace(/_/g, " ") }}</label>
                                <input v-model="new_data[field.name]" type="text" :id="field.name" class="form-control" required>
                            </div>
                        </div>
                    </div>
                    <div>

                        <button @click="createPostGoogleAd" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Post Google Advert
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "CreatePostAds",
    components: {},
    data: () => ({
        loading: false,
        fields: [{
                'name': 'title',
                'type': 'input'
            },
            {
                'name': 'link',
                'type': 'input'
            },
            {
                'name': 'amount',
                'type': 'input'
            },
            {
                'name': 'image',
                'type': 'file'
            },
        ],
        fields_google: [{
                'name': 'title',
                'type': 'input'
            },
            {
                'name': 'google_ad_code',
                'type': 'input'
            },
        ],
        data: {},
        new_data: {},
    }),
    methods: {
        async createPostAd() {
            this.loading = true
            try {
                var apiResponse = await ApiService.SavePostAds(this.data)
                if (apiResponse['status'] == 'success') {
                    this.data = ''
                    location.href = '/dashboard/post-ads'
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async createPostGoogleAd() {
            this.loading = true
            try {
                var apiResponse = await ApiService.SavePostGoogleAds(this.new_data)
                if (apiResponse['status'] == 'success') {
                    this.data = ''
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                location.href = '/dashboard/post-ads'
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async uploadFile(name, e) {
            this.loading = true
            try {
                const file = e.target.files[0]
                const res = await ApiService.UploadFile(file)
                console.log('=======res', res)
                if (res) {
                    this.notifyUser(res['status'], res['message'])
                }
                if (res['status'] == 'success') {
                    console.log("===", res.data.file_name)
                    this.data[name] = res.data.file_name
                }
            } catch (e) {
                // window.alert(e.message)
                this.notifyUser('error', e.message)
            } finally {
                this.loading = false
            }
        },
    },
    computed: {
        tableHeadings() {
            return []
        }
    },
    mounted() {},
    watch: {}
}
</script>

<style scoped>
    </style>
