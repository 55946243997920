<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="accountsData['total_page']">{{ (parseInt(accountsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{accountsData['total_accounts'].toLocaleString()}} Accounts</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchAccounts" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User Email
                                </th>
                                <th scope="col">
                                    User Name
                                </th>
                                <th scope="col">
                                    Account Number
                                </th>
                                <th scope="col">
                                    Account Name
                                </th>
                                <th scope="col">
                                    Bank Name
                                </th>
                                <th scope="col">
                                    Account Type
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allAccounts" :key="item.id">
                                <td>
                                    <span v-if="item['user']">
                                        <a :href="'/dashboard/user/'+item['user']['id']">
                                            {{ item['user']['email'] }}
                                        </a>
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['user']">
                                        {{ item['user']['name'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['account_no'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['name'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['bank'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['account_type'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Accounts",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allAccountsData: [],
        allAccounts: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        accountsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_accounts_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Accounts",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allAccounts)
            this.exporting = false
        },
        async getAccounts() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetAccounts(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.accountsData = apiResponse['data']
                    this.allAccounts = this.accountsData['accounts']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchAccounts() {
            this.page = 0
            this.getAccounts()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getAccounts()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.accountsData['total_page']) {
                this.page = this.page + 1
                this.getAccounts()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getAccounts();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "name",
                "bank",
                "account_no",
                "account_type",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getAccounts()
    },
    watch: {}
}
</script>

<style scoped>
</style>
