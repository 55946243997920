<template>
  <div
    class="modal fade"
    id="add-sub-category"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add-sub-category"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <template v-if="data">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">Add SubCategory</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label" for="name">Name</label>
                  <input v-model="name" type="text" id="name" class="form-control" required placeholder="name">
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label" for="category_id">Category</label>
                  <select v-model="category_id" id="category_id" class="form-control" required>
                    <option value="" disabled>Select Category</option>
                    <option v-for="c in categories" :value="c.id">{{ c.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="save"
              type="button"
              class="btn btn-primary"
              :disabled="saving"
              :data-loading="saving"
            >
              Save
            </button>
            <button type="button" class="btn btn-link ml-auto" data-dismiss="modal">Close</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex"
  import ApiService from '@/services/api_service'

  export default {
    name: "AddSubCategory",
    data: () => ({
      saving: false,
      name: '',
      category_id: '',
      categories: '',
      uploadingFile: false,
      data: {}
    }),
    methods: {
      async save () {
        this.saving = true
        try {
          const res = await ApiService.SaveProductSubCategory(this.category_id, this.name)
          this.data = {}
          this.notifyUser(res.status, res.message)
          if(res.status == 'success'){
            window.$("#add-sub-category").modal("hide")
            location.href = ''
          }
        } catch (e) {
        } finally {
          this.saving = false
        }
      },
      async getProductExtraInfos () {
        this.loading = true
        try {
          var apiResponse = await ApiService.GetProductExtraInfos(this.page, this.search)
          if(apiResponse['status'] == 'success'){
            this.categories = apiResponse['data']['categories']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
    },
    mounted (){
        this.getProductExtraInfos()
    },
    computed: {
    }
  }
</script>

<style scoped>

</style>
