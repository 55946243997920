import api from "../../api"

const defaultState = () => ({
    user: localStorage.getItem("user"),
    token: localStorage.getItem("token")
})

const logoutEvent = new Event("logout")

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        isAuthenticated: state => !!state.user && !!state.token
    },
    mutations: {
        SET_USER: (state, data) => {
            state.user = data
        },
        SET_TOKEN: (state, data) => {
            state.token = data
        },
        RESET_STATE: state => {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        async login(context, { email, password }) {
            const res = await api.login(email, password)
            if (res && res.data) {
                const user = res.data.user
                const token = res.data.token
                context.commit("SET_USER", user)
                context.commit("SET_TOKEN", token)
                window.setItem("user", user)
                window.setItem("token", token)
                api.setToken(token)
                return res
            }
            throw new Error(res)
        },
        async logout() {
            window.dispatchEvent(logoutEvent)
        }
    }
}