<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="productsData['total_page']">{{ (parseInt(productsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{productsData['total_products'].toLocaleString()}} Products</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchProducts" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th v-for="field in userFields('mini')" :key="'u_'+field" scope="col">
                                    Posted By: {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allProducts" :key="item.id">
                                <td v-for="field in tableHeadings" :key="'thead-'+field">
                                    {{ item[field] }}
                                </td>
                                <td v-for="field in shopFields('mini')" :key="'shop_'+field">
                                    <a v-if="item['shop']" :href="'/dashboard/shop/'+item['shop']['id']">{{ item['shop'][field] }}</a>
                                </td>
                                <td v-for="field in userFields('mini')" :key="'user_'+field">
                                    <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/job/'+item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Products",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allProductsData: [],
        allProducts: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        productsData: []
    }),
    methods: {
        async getProducts() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetProducts(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.productsData = apiResponse['data']
                    this.allProducts = this.productsData['products']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchProducts() {
            this.page = 0
            this.getProducts()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getProducts()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.productsData['total_page']) {
                this.page = this.page + 1
                this.getProducts()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getProducts();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "description",
                "category_id",
                "sub_category_id",
                "amount",
                "currency",
                "in_stock",
                "unique_views_count",
                "status",
                "created_at"
            ]
        }
    },
    mounted() {
        this.getProducts()
    },
    watch: {}
}
</script>

<style scoped>
</style>
