<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="coupon_codes_data">{{ (parseInt(coupon_codes_data['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{coupon_codes_data['total_coupon_codes'].toLocaleString()}} Coupon Codes</h3>
                            <a href="/dashboard/create-coupon-code" class="btn btn-success text-white">Create Coupon Code</a>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchCouponCodes" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User
                                </th>
                                <th scope="col">
                                    Owner
                                </th>
                                <th scope="col">
                                    Amount
                                </th>
                                <th scope="col">
                                    Code
                                </th>
                                <th scope="col">
                                    Used By
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in coupon_codes" :key="item.id">
                                <td>
                                    <span v-if="item['created_by']">
                                        {{ item['created_by']['name'] }}<br />
                                        {{ item['created_by']['email'] }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['created_for']">
                                        {{ item['created_for']['name'] }}<br />
                                        {{ item['created_for']['email'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['amount'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['code'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['used_by_count'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['status'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <button v-if="item.status == 'new'" class="btn btn-danger" @click="deactivateCouponCode(item.id)">
                                        deactivate
                                    </button>
                                    <button v-else-if="item.status == 'deactive'" class="btn btn-primary" @click="activateCouponCode(item.id)">
                                        activate
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "CouponCodes",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        coupon_codes: [],
        coupon_codes_data: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_subscriptions_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend CouponCodes",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.coupon_codes)
            this.exporting = false
        },
        async getCouponCodes() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetCouponCodes(this.page, this.search, this.code_section)
                if (apiResponse['status'] == 'success') {
                    this.coupon_codes_data = apiResponse['data']
                    this.coupon_codes = this.coupon_codes_data['coupon_codes']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async activateCouponCode(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.ActivateCouponCode(id)
                if (apiResponse['status'] == 'success') {
                    this.getCouponCodes()
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async deactivateCouponCode(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.DeactivateCouponCode(id)
                if (apiResponse['status'] == 'success') {
                    this.getCouponCodes()
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchCouponCodes() {
            this.page = 0
            this.getCouponCodes()
            if (this.search) {} else {}
        },
        async changeSectionCouponCodes(a) {
            this.page = 0
            this.code_section = a
            this.getCouponCodes()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getCouponCodes()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.coupon_codes_data['total_page']) {
                this.page = this.page + 1
                this.getCouponCodes()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getCouponCodes();
        },
    },
    computed: {
        tableHeadings() {
            return []
        }
    },
    mounted() {
        this.getCouponCodes()
    },
    watch: {}
}
</script>
    
    
<style scoped>
    </style>
