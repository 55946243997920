<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0">
                        <p v-if="pagination && pagination['last_page']">{{ (parseInt(pagination['last_page'])).toLocaleString() }} Page(s)</p>
                        <div class="row align-items-center">
                            <div class="col flex">
                                <h3 v-if="!loading" class="mb-0">{{pagination['total'].toLocaleString()}} PageItems</h3>
                                <div class="">
                                    <button @click="changeSection('all')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                        All
                                    </button>
                                    <button @click="changeSection('verified')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                        Verified
                                    </button>
                                    <button @click="changeSection('unverified')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                        Unverified
                                    </button>
                                </div>
                            </div>
                            <div class="col px-3 d-flex">
                                <input v-model="search" class="form-control" placeholder="type and click the search button" />
                                <button @click="searchPageItems" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <LinearLoader v-if="loading"></LinearLoader>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">
                                        User
                                    </th>
                                    <th scope="col">
                                        Name
                                    </th>
                                    <th scope="col">
                                        Email
                                    </th>
                                    <th scope="col">
                                        Category
                                    </th>
                                    <th scope="col">
                                        Website
                                    </th>
                                    <th scope="col">
                                        Image
                                    </th>
                                    <th scope="col">
                                        Page Views
                                    </th>
                                    <th scope="col">
                                        Status
                                    </th>
                                    <th scope="col">
                                        Description
                                    </th>
                                    <th scope="col">
                                        Date
                                    </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in allPageItems" :key="item.id">
                                    <td>
                                        <span v-if="item['user']">
                                            <a :href="'/dashboard/user/'+item.user.id">
                                                {{ item['user']['name'] }}<br />
                                                {{ item['user']['email'] }}
                                            </a>
                                        </span>
                                    </td>
                                    <td>
                                        <p>
                                            {{ item['name'] }}
                                        </p>
                                        <p v-if="item['verified']">
                                            <span v-if="item['verified'] == 'true'" class="badge badge-success">
                                                {{ item['verified'] }}
                                            </span>
                                            <span v-if="item['verified'] == 'false'" class="badge badge-danger">
                                                {{ item['verified'] }}
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['email'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="badge badge-primary">
                                            {{ item['category'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['website'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <img style="height: 30px" :src="item.image" />
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['page_views'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <p>
                                            <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                            <span v-else-if="item['status'] == 'active'" class="badge badge-primary">{{ item['status'] }}</span>
                                            <span v-else-if="item['status'] == 'paused'" class="badge badge-info">{{ item['status'] }}</span>
                                            <span v-else-if="item['status'] == 'pending'" class="badge badge-danger">{{ item['status'] }}</span>
                                            <span v-else class="badge badge-secondart">{{ item['status'] }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p style="max-width: 50vw; word-wrap: break-word; word-break: break-all;" :html="item['description']">
                                            
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {{ item['created_at'] }}
                                        </p>
                                        <p>
                                            {{ item['updated_at'] }}
                                        </p>
                                    </td>
                                    <td class="text-right">
                                        <a :href="vtSocial()+'/dashboard/page/'+item.id" target="_blank" class="btn btn-primary btn-sm">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                        <button @click="managePageItemVerification('unverify', item['id'])" v-if="item['verified'] == 'true'" class="btn btn-danger">unverify</button>
                                        <button @click="managePageItemVerification('verify', item['id'])" v-if="item['verified'] == 'false' || item['verified'] == '' || item['verified'] == null" class="btn btn-success">verify</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mb-3 mx-4">
                        <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                            Previous
                        </button>
                        <template v-for="this_page in total_page">
                            <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                                {{ this_page }}
                            </button>
                            <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                                {{ this_page }}
                            </button>
                        </template>
                        <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        ExportToCsv
    } from "export-to-csv"
    import ApiService from '@/services/api_service'
    
    export default {
        name: "PageItems",
        components: {},
        data: () => ({
            exporting: false,
            loading: true,
            selectedId: null,
            defFields: [],
            search: "",
            section: "all",
            searchResult: [],
            allPageItemsData: [],
            allPageItems: [],
            fields: [],
            page: 0,
            total_page: 0,
            current_page: 0,
            pageItemsData: [],
            pagination: '',
        }),
        methods: {
            exportToCSV() {
                this.exporting = true
                const options = {
                    filename: `viraltrend_pageItems_${Date.now()}`,
                    fieldSeparator: ",",
                    quoteStrings: "\"",
                    decimalSeparator: ".",
                    showLabels: true,
                    showTitle: true,
                    title: "Viraltrend PageItems",
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true
                }
                const csvExporter = new ExportToCsv(options)
                csvExporter.generateCsv(this.allPageItems)
                this.exporting = false
            },
            async getPageItems() {
                this.loading = true
                try {
                    var apiResponse = await ApiService.GetPages(this.page, this.section, this.search)
                    if (apiResponse['status'] == 'success') {
                        this.pageItemsData = apiResponse['data']
                        this.allPageItems = this.pageItemsData['pages']
                        this.pagination = apiResponse['data']['pagination']
                        this.total_page = this.pagination["last_page"];
                        this.current_page = parseInt(this.pagination["current_page"]);
                        if (this.current_page + 10 < this.total_page) {
                            this.total_page = this.current_page + 10
                        }
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            },
            async managePageItemVerification(type, id) {
                this.loading = true
                try {
                    if(type == 'verify'){
                        var apiResponse = await ApiService.VerifyPage(id)
                        if (apiResponse['status'] == 'success') {
                            this.getPageItems()
                        } else {
                            this.notifyUser(apiResponse['status'], apiResponse['message'])
                        }
                    }else if(type == 'unverify'){
                        var apiResponse = await ApiService.UnverifyPage(id)
                        if (apiResponse['status'] == 'success') {
                            this.getPageItems()
                        } else {
                            this.notifyUser(apiResponse['status'], apiResponse['message'])
                        }
                    }else{
                        this.notifyUser('error', 'type not understood')
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            },
            async searchPageItems() {
                this.page = 0
                if (this.search) {
                    this.section = 'search'
                } else {
                    this.section = 'all'
                }
                this.getPageItems()
            },
            async previousPage() {
                if (this.page > 0) {
                    this.page = this.page - 1
                    this.getPageItems()
                } else {
                    this.notifyUser('warning', 'This is the first page')
                }
            },
            async nextPage() {
                if (this.page < this.pageItemsData['total_page']) {
                    this.page = this.page + 1
                    this.getPageItems()
                } else {
                    this.notifyUser('warning', 'This is the last page')
                }
            },
            async openPage(a) {
                this.page = a;
                this.getPageItems();
            },
            async changeSection(a) {
                this.section = a
                this.getPageItems()
            },
        },
        computed: {
            tableHeadings() {
                return [
                    "id",
                    "topic",
                    "user_id",
                    "email",
                    "category",
                    "page_views",
                    "status",
                    "created_at",
                    "updated_at"
                ]
            }
        },
        mounted() {
            this.getPageItems()
        },
        watch: {}
    }
    </script>
    
    <style scoped>
    </style>
    