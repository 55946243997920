<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" v-if="advert">Name: {{ advert.platform }}</h3>
                        </div>
                        <div class="col text-right" v-if="advert">
                            <button @click="manageAdvert('pause', advert['id'])" v-if="advert['status'] == 'active'" class="btn btn-danger">pause</button>
                            <button @click="manageAdvert('activate', advert['id'])" v-if="advert['status'] == 'paused'" class="btn btn-success">activate</button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div v-if="advert && loading == false" class="row p-3">
                    <div v-if="advert" class="col-xl-6 col-md-6 col-sm-12">

                        <div class="mt-2">
                            <h3 class="card-title text-uppercase text-muted mb-2">Advert Info</h3>
                            <p class="h4 font-weight-bold">ID: {{ advert.id }}</p>
                            <p class="">Platform: {{ advert.platform }}</p>
                            <p class="">Type: {{ advert.type }}</p>
                            <p class="">Amount Earned: {{ advert['amount'] }}NGN</p>
                            <p class="" v-if="advert.comment">Type: {{ advert.coment }}</p>
                            <p class="">Target: {{ advert.users_needed }}</p>
                            <p class="">Users Completed: {{ advert.users_completed.toLocaleString() }}</p>
                            <p class="">Users Accepted: {{ advert.users_completed_list.length.toLocaleString() }}</p>
                            <p class="">Amount Paid: {{ Number(advert.users_completed*advert['amount']).toFixed(2) }}NGN</p>
                            <p class="">Countries: {{ advert.countries }}</p>
                            <p class="">Url: {{ advert.url }}</p>
                            <p class="">Status: {{ advert.status }}</p>
                            <p class="">Created By: <a :href="'/dashboard/user/'+advert['user_id']">{{ advert['user_id'] }}</a></p>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <p v-if="selectedUserTasks.length">
                            {{ selectedUserTasks.length }} Selected
                        </p>
                        <h3 class="card-title text-uppercase text-muted mb-2">Users who accepted the task</h3>
                        <div class="">
                            <button class="btn btn-success" @click="approveAllUserTask(advert.id)">
                                approve and pay all pending approval
                            </button>
                            <button v-if="selectedUserTasks.length > 0" class="btn btn-primary" @click="approveAllSelectedUserTask">
                                approve all selected
                            </button>
                            <button v-if="selectedUserTasks.length > 0" class="btn btn-danger" type="button" data-toggle="modal" data-target="#reject-all-user-task">
                                reject all selected
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">User ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Social ID</th>
                                        <th scope="col">Proof</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user_task in advert.users_completed_list">
                                        <td>{{ user_task['id'] }}</td>
                                        <td>
                                            <a :href="'/dashboard/user/'+user_task['user_id']">{{ user_task['user_id'] }}</a>
                                        </td>
                                        <td>
                                            <span v-if="user_task['user']">{{ user_task['user']['name'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="user_task['user']">{{ user_task['user']['email'] }}</span>
                                        </td>
                                        <td>
                                            {{ user_task['social'] }}
                                            <div v-if="user_task['user_social']">
                                                <div class="badge badge-success" v-if="advert.platform == 'instagram'">Main Account: {{ user_task['user_social']['instagram'] }}</div>
                                                <div class="badge badge-success" v-if="advert.platform == 'facebook'">Main Account: {{ user_task['user_social']['facebook'] }}</div>
                                                <div class="badge badge-success" v-if="advert.platform == 'twitter'">Main Account: {{ user_task['user_social']['twitter'] }}</div>
                                                <div class="badge badge-success" v-if="advert.platform == 'youtube'">Main Account: {{ user_task['user_social']['youtube'] }}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="user_task['proof_of_task_url']">
                                                <a :href="user_task['proof_of_task_url']" target="_blank">
                                                    <img :src="user_task['proof_of_task_url']" style="height:200px" />
                                                </a>
                                                <div class="mt-4" v-if="user_task.status == 'pending_approval'">
                                                    <div class="form-check">
                                                        <input class="form-control" style="height:20px" type="checkbox" value="" :id="'check_'+user_task['id']" @click="manageCheckbox(user_task['id'])" />
                                                      </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>
                                                <span v-if="user_task['status'] == 'complete' || user_task['status'] == 'completed'" class="badge badge-success">{{ user_task['status'] }}</span>
                                                <span v-else-if="user_task['status'] == 'active' || user_task['status'] == 'pending_approval'" class="badge badge-primary">{{ user_task['status'] }}</span>
                                                <span v-else-if="user_task['status'] == 'paused'" class="badge badge-info">{{ user_task['status'] }}</span>
                                                <span v-else-if="user_task['status'] == 'pending'" class="badge badge-danger">{{ user_task['status'] }}</span>
                                                <span v-else class="badge badge-secondary">{{ user_task['status'] }}</span>
                                            </p>
                                            <p v-if="user_task.status == 'denied'" class="badge badge-info">
                                                {{ user_task['reason'] }}
                                            </p>
                                        </td>
                                        <td>{{ user_task['created_at'] }}</td>
                                        <td class="text-right">
                                            <div v-if="user_task.user">
                                                <div class="mb-2">
                                                    <a class="btn btn-info" :href="'/dashboard/user/'+user_task.user.id">
                                                        view user
                                                    </a>
                                                </div>
                                                <button v-if="user_task.user.status == 'deactive'" class="btn btn-primary" @click="unbanUser(user_task.user_id)">
                                                    unban
                                                </button>
                                                <button v-else class="btn btn-danger" @click="banUser(user_task.user_id)">
                                                    ban
                                                </button>
                                                <button v-if="user_task.status == 'pending_approval'" class="btn btn-success" @click="approveUserTask(user_task.id)">
                                                    approve and pay
                                                </button>
                                                <button @click="setCurrentUserTask(user_task)" v-if="user_task.status == 'pending_approval'" class="btn btn-danger" type="button" data-toggle="modal" data-target="#reject-user-task">
                                                    deny
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else-if="!advert && loading == false" class="text-center">
                    <h4>Advert not found or was deleted</h4>
                </div>
                <div v-else class="text-center">
                    <h4></h4>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reject-user-task" tabindex="-1" role="dialog" aria-labelledby="reject-user-task" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <template v-if="currentUserTask">
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-title-default">Reject Uploaded Proof</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="reason">Reason for rejection</label>
                                    <input v-model="reason" type="text" id="reason" class="form-control" required placeholder="reason">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="denyUserTask(currentUserTask['id'], reason)" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Reject
                        </button>
                        <button type="button" class="btn btn-link ml-auto" data-dismiss="modal">Close</button>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reject-all-user-task" tabindex="-1" role="dialog" aria-labelledby="reject-all-user-task" aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <template v-if="selectedUserTasks.length > 0">
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-title-default">Reject Uploaded Proof</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="all_reason">Reason for rejection</label>
                                    <input v-model="all_reason" type="text" id="all_reason" class="form-control" required placeholder="reason for use for all rejection">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="rejectAllSelectedUserTask(all_reason)" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Reject
                        </button>
                        <button type="button" class="btn btn-link ml-auto" data-dismiss="modal">Close</button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApiService from '@/services/api_service'

export default {
    name: "Advert",
    components: {},
    data: () => ({
        id: "",
        loading: "",
        reason: "",
        all_reason: "",
        advert: "",
        currentUserTask: "",
        selectedUserTasks: []
    }),
    methods: {
        async getAdvert() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetAdvert({
                    id: this.id
                })
                if (apiResponse['status'] == 'success') {
                    this.advert = apiResponse['data']['advert']
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async manageAdvert(type, id) {
            this.loading = true
            try {
                let data = {
                    type,
                    id
                }
                var apiResponse = await ApiService.ManageAdvert(data)
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async banUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.BanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async unbanUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.UnbanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async approveUserTask(id) {
            this.loading = true;
            try {
                let proceed = confirm("Are you sure you want to approve user task with given Proof");
                if (proceed) {
                    var apiResponse = await ApiService.ApprovePendingApprovalUserTask(id);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async denyUserTask(id, reason) {
            this.loading = true;
            try {
                let proceed = confirm("Are you sure you want to deny this user task with given reason: " + reason);
                if (proceed) {
                    var apiResponse = await ApiService.DenyPendingApprovalUserTask(id, reason);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async approveAllUserTask(id) {
            this.loading = true;
            try {
                let proceed = confirm("Are you sure you want to approve all user task with pending approval");
                if (proceed) {
                    var apiResponse = await ApiService.ApproveAllPendingApprovalUserTask(id);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async setCurrentUserTask(task) {
            this.currentUserTask = task
        },
        async manageCheckbox(id) {
            let checkbox = document.getElementById('check_' + id)
            if (checkbox.checked) {
                this.selectedUserTasks.push(id)
            } else {
                let index = this.selectedUserTasks.indexOf(id)
                if (index > -1) {
                    this.selectedUserTasks.splice(index, 1)
                }
            }
        },
        async approveAllSelectedUserTask() {
            this.loading = true
            try {
                let proceed = confirm("Are you sure you want to approve all selected user task with pending approval");
                if (proceed) {
                    for (let i = 0; i < this.selectedUserTasks.length; i++) {
                        var apiResponse = await ApiService.ApprovePendingApprovalUserTask(this.selectedUserTasks[i]);
                        this.notifyUser(apiResponse["status"], apiResponse["message"]);
                        if (apiResponse["status"] == "success") {
                            setTimeout(() => {
                                location.href = "";
                            }, 1300);
                        } else {}
                    }
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async rejectAllSelectedUserTask() {
            this.loading = true
            try {
                let proceed = confirm("Are you sure you want to reject all selected user task with pending approval");
                if (proceed) {
                    for (let i = 0; i < this.selectedUserTasks.length; i++) {
                        var apiResponse = await ApiService.DenyPendingApprovalUserTask(this.selectedUserTasks[i], this.all_reason);
                        this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    }
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.id = this.$route.params['id']
        this.getAdvert(this.id.toString())
    }
}
</script>

<style scoped>
</style>
