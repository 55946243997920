<template>
<div>
    <LinearLoader v-if="loading" absolute></LinearLoader>
    <div v-if="!loading" class="container-fluid mt-5">
        <div class="row" v-if="stats['subscriptions_today']">
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions Today</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_today'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions Yesterday</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_yesterday'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions This Week</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_this_week'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions Last Week</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_last_week'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions This Month</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_this_month'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Subscriptions Last Month</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions_last_month'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment Today</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_today'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment Yesterday</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_yesterday'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment This Week</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_this_week'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment Last Week</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_last_week'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment This Month</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_this_month'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats">
                    <!-- Card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">Ad Payment Last Month</h5>
                                <span class="h2 font-weight-bold mb-0">{{ stats['ad_payment_last_month'].toLocaleString() || 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApiService from '@/services/api_service'

export default {
    name: "FinanceOverview",
    data: () => ({
        filterBy: "",
        period: 1,
        stats: [],
        startDate: "",
        endDate: "",
        loading: true
    }),
    watch: {
        filterBy() {
            this.getStatistics()
        },
        period() {
            this.getStatistics()
        },
        startDate(val) {
            if (val) {
                this.getStatistics()
            }
        },
        endDate(val) {
            if (val) {
                this.getStatistics()
            }
        }
    },
    methods: {
        async getAllFinanceStats() {
            this.loading = true
            try {
                var apiResponse = await ApiService.AllFinanceStats()
                if (apiResponse['status'] == 'success') {
                    this.stats = apiResponse['data']
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        }
    },
    computed: {},
    mounted() {
        Promise.all([
            this.getAllFinanceStats()
        ]).finally(() => (this.loading = false))
    }
}
</script>

<style scoped>

</style>
