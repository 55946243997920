<template>
<div>
    <nav class="sidenav navbar navbar-vertical fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
        <div class="scrollbar-inner">
            <!-- Brand -->
            <div class="sidenav-header  align-items-center">
                <a class="navbar-brand" href="javascript:void(0)">
                    <img src="/icon.png" class="navbar-brand-img" alt="...">
                </a>
            </div>
            <div class="navbar-inner">
                <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                    <ul class="navbar-nav custom_menu_manager">
                        <li @click="closeSBMobile" class="nav-item">
                            <router-link :to="{ name: 'overview' }" class="nav-link" exact>
                                <i class="ni ni-chart-bar-32 text-primary"></i>
                                <span class="nav-link-text">Overview</span>
                            </router-link>
                        </li>
                        <li v-on:click="toggleMenu('content')" class="content nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Content</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'feeds' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Feeds</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'jobs' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Jobs</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'blogs' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Blogs</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'forums' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Forums</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'posts' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Posts</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'events' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Events</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'groups' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Groups</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'pages' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Pages</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!-- <li v-on:click="toggleMenu('tasks')" class="tasks nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Tasks</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                            </ul>
                        </li> -->
                        <li v-on:click="toggleMenu('sales')" class="sales nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Sales</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'products' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Products</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'products-extra-info' }" class="nav-link">
                                        <i class="ni ni-settings text-primary"></i>
                                        <span class="nav-link-text">Products Extra Info</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orders' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Orders</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'courses' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Courses</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'finance-overview' }" class="nav-link">
                                        <i class="ni ni-chart-bar-32 text-primary"></i>
                                        <span class="nav-link-text">Finance Overview</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-on:click="toggleMenu('messaging')" class="messaging nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Messaging</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'notifications' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Notifications</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-on:click="toggleMenu('advertising')" class="advertising nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Advertising</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'adverts' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Adverts</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'post-ads' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Post Ads</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-on:click="toggleMenu('payments')" class="payments nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Payments</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'transactions' }" class="nav-link">
                                        <i class="ni ni-archive-2 text-primary"></i>
                                        <span class="nav-link-text">Transactions</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'subscriptions' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Subscriptions</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'withdrawals' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Withdrawals</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'vitisocial-withdrawals' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Vitisocial Withdrawals</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'wallets' }" class="nav-link">
                                        <i class="ni ni-money-coins text-primary"></i>
                                        <span class="nav-link-text">Wallets</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'coupon-codes' }" class="nav-link">
                                        <i class="ni ni-satisfied text-primary"></i>
                                        <span class="nav-link-text">Coupon Codes</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-on:click="toggleMenu('users')" class="users nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Users</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'accounts' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Accounts</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'shops' }" class="nav-link">
                                        <i class="ni ni-notification-70 text-primary"></i>
                                        <span class="nav-link-text">Shops</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'verification-applications' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Verification Applications</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'users' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Users</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'admins' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">Admins</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'user-stats' }" class="nav-link">
                                        <i class="ni ni-single-02 text-primary"></i>
                                        <span class="nav-link-text">User Stats</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-on:click="toggleMenu('others')" class="others nav-item">
                            <a class="nav-link">
                                <i class="fa fa-plus text-primary"></i>
                                <span class="nav-link-text">Others</span>
                            </a>
                            <ul @click="closeSBMobile" class="navbar-nav m-0 pl-4">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'reports' }" class="nav-link">
                                        <i class="ni ni-settings text-primary"></i>
                                        <span class="nav-link-text">Reports</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'settings' }" class="nav-link">
                                        <i class="ni ni-settings text-primary"></i>
                                        <span class="nav-link-text">Settings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'admin-wallet-logs' }" class="nav-link">
                                        <i class="ni ni-settings text-primary"></i>
                                        <span class="nav-link-text">Admin Wallet Logs</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'search' }" class="nav-link">
                                        <i class="ni ni-settings text-primary"></i>
                                        <span class="nav-link-text">Search</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    <div class="main-content" id="panel">
        <nav class="navbar navbar-top navbar-expand navbar-light bg-white border-bottom" style="box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);">
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav align-items-center ml-md-auto ">
                        <li class="nav-item d-xl-none">
                            <div class="pr-3 sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                                <div class="sidenav-toggler-inner">
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
                        <li class="nav-item dropdown">
                            <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="media align-items-center">
                                    <span class="avatar avatar-sm rounded-circle">
                                        <!--                    <img alt="" src="">-->
                                        <i class="ni ni-single-02 text-white"></i>
                                    </span>
                                </div>
                            </a>
                            <div class="dropdown-menu  dropdown-menu-right ">
                                <a href="javascript:;" class="dropdown-item">
                                    <span>{{ user.email }} - {{ user.name }}</span>
                                </a>
                                <a href="javascript:;" class="dropdown-item">
                                    <span>Profile</span>
                                </a>
                                <a href="javascript:;" class="dropdown-item">
                                    <span>Settings</span>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a @click="logoutAndRedirect" class="dropdown-item mouse-pointer">
                                    <span>Logout</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Page content -->
        <router-view></router-view>
    </div>
</div>
</template>

<script>
export default {
    name: "Layout",
    data: () => ({
        loading: false,
        user: "",
    }),
    mounted() {
        let url  = location.href
        if(url.search('viraltrend.org') > -1){
            location.href = 'https://vtadmin.vitisocial.com'
        }
        this.user = this.getUser()
    },
    methods: {
        async logoutAndRedirect() {
            this.logout()
            location.href = '/login'
        },
        toggleMenu(a) {
            let thisItem = $('.' + a + ' ul').css('display')
            console.log(':::', a, thisItem)
            if (thisItem == 'none') {
                $('.custom_menu_manager li ul').hide()
                $('.custom_menu_manager li i.fa-minus').removeClass('fa-minus').addClass('fa-plus')
                $('.' + a + ' i.fa').removeClass('fa-plus').addClass('fa-minus')
                $('.' + a + ' ul').show()
            } else {
                $('.custom_menu_manager li ul').hide()
                $('.custom_menu_manager li i.fa-minus').removeClass('fa-minus').addClass('fa-plus')
            }
        },
        closeSBMobile() {
            let isMobile = this.isMobile()
            console.log('=====',this.isMobile())
            if(isMobile == true){
                $('.g-sidenav-show').removeClass('g-sidenav-pinned')
                $('.g-sidenav-show').addClass('g-sidenav-hidden')
                $('.backdrop.d-xl-none').remove()
                // $("#navbarSupportedContent").click()
            }
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>
p {
    font-weight: normal;
}

.custom_menu_manager li ul {
    display: none;
}

.custom_menu_manager li a {
    cursor: pointer;
}
.mouse-pointer {
    cursor: pointer;
}
.input-sm {
    height: 28px;
}
.table td, .table th {
    font-size: 0.95rem;
}
.table thead th {
    font-size: .9rem;
}
.navbar-vertical .navbar-nav .nav-link {
    font-size: .875rem;
}
</style>
