<template>
<div class="modal fade" id="update-setting" tabindex="-1" role="dialog" aria-labelledby="update-setting" aria-hidden="true">
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
            <template v-if="data">
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">Update Setting</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="name">Name</label>
                                <input v-model="name" type="text" id="name" class="form-control" required placeholder="name">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="value">Value</label>
                                <input v-model="value" type="text" id="value" class="form-control" required placeholder="name">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="updateSetting" type="button" class="btn btn-primary" :disabled="saving" :data-loading="saving">
                        Update
                    </button>
                    <button type="button" class="btn btn-link ml-auto" data-dismiss="modal">Close</button>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

  
  
<script>
import {
    mapActions
} from "vuex"
import ApiService from '@/services/api_service'

export default {
    name: "UpdateSetting",
    props: {
        setting: Object,
    },
    data: () => ({
        saving: false,
        id: '',
        name: '',
        value: '',
        data: {}
    }),
    mounted(){
        if(this.setting){
            this.id = this.setting.id
            this.name = this.setting.name
            this.value = this.setting.value
        }
    },
    methods: {
        async updateSetting() {
            this.saving = true
            try {
                const res = await ApiService.UpdateSetting(this.id, this.name, this.value)
                this.data = {}
                this.notifyUser(res.status, res.message)
                if (res.status == 'success') {
                    location.href = ''
                }
            } catch (e) {} finally {
                this.saving = false
            }
        }
    },
    computed: {}
}
</script>
  
  
<style scoped>
  
  </style>
