<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-flex">
                        <p class="mr-3" v-if="pagination['last_page']">{{ (parseInt(pagination['last_page'])).toLocaleString() }} Page(s)</p>
                        <p v-if="stats">
                            {{ (parseInt(stats['ok'])).toLocaleString() }} ok, 
                            {{ (parseInt(stats['suspect'])).toLocaleString() }} suspects,
                        </p>
                    </div>
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 v-if="!loading" class="mb-0">{{pagination['total'].toLocaleString()}} UserStats</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchUserStats" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User
                                </th>
                                <th scope="col">
                                    Balance/Country
                                </th>
                                <th scope="col">
                                    Inflow
                                </th>
                                <th scope="col">
                                    Outflow
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">
                                    Last Updated
                                </th>
                                <th scope="col">
                                    Subscription
                                </th>
                                <th scope="col">Total tasks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allUserStats" :key="item.id">
                                <td>
                                    <span v-if="item['user']">
                                        <a :href="'/dashboard/user/'+item.user.id">
                                            {{ item['user']['name'] }}<br/>
                                            {{ item['user']['email'] }}<br/>
                                        </a>
                                    </span>
                                </td>
                                <td>
                                    <span class="text-capitalize">
                                        {{ item['balance'] }}<br />
                                        Country: {{ item['country'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ currencySymbol(item['country']) }}{{ item['inflow'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ currencySymbol(item['country']) }}{{ item['outflow'] }}
                                    </span>
                                </td>
                                <td>
                                    <p>
                                        <span v-if="item['status'] == 'ok'" class="badge badge-success">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'suspect'" class="badge badge-danger">{{ item['status'] }}</span>
                                        <span v-else class="badge badge-secondary">{{ item['status'] }}</span>
                                    </p>
                                </td>
                                <td>
                                    <span>
                                        {{ item['updated_at'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['subscription_amount'] }}
                                    </span>
                                    <br/>
                                    <span class="badge badge-info">
                                        {{ item['subscription_name'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['user_tasks'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "UserStats",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allUserStatsData: [],
        allUserStats: [],
        stats: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        userStat: [],
        pagination: [],
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_user_stats_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend UserStats",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allUserStats)
            this.exporting = false
        },
        async getUserStats() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetUserStats(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.userStat = apiResponse['data']
                    this.stats = apiResponse['stats']
                    this.allUserStats = this.userStat
                    this.pagination = apiResponse['pagination']
                    this.total_page = this.pagination["last_page"];
                    this.current_page = parseInt(this.pagination["current_page"]);
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchUserStats() {
            this.page = 0
            this.getUserStats()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getUserStats()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.total_page) {
                this.page = this.page + 1
                this.getUserStats()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getUserStats();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "amount",
                "type",
                "platform",
                "url",
                "comment",
                "users_needed",
                "expire",
                "countries",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getUserStats()
    },
    watch: {}
}
</script>

<style scoped>
</style>
