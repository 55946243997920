<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col d-flex">
                                <h3 class="mb-0">Create Advert</h3>
                                <a href="/dashboard/adverts" class="ml-4 btn btn-success text-white">View Adverts</a>
                            </div>
                            <div class="col px-3 d-flex">
                            </div>
                        </div>
                    </div>
                    <LinearLoader v-if="loading"></LinearLoader>
                    <div class="card-header col-sm-8 m-auto">
    
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="">Platform and Type</label>
                                    <select v-model="platform_type" @change="setPlatformType" required="required" id="" class="form-control populate  viral-select">
                                        <option value="" selected="selected">- Select an Advert -</option> 
                                        <option value="facebook-like">Facebook Like</option> 
                                        <option value="facebook-follow">Facebook Follow</option> 
                                        <option value="facebook-view">Facebook View</option> 
                                        <option value="facebook-comment">Facebook Comment</option> 
                                        <option value="facebook-share">Facebook Share</option> 
                                        <option value="twitter-follow">Twitter Follow</option> 
                                        <option value="twitter-retweet">Twitter Retweet</option> 
                                        <option value="twitter-like">Twitter Like</option> 
                                        <option value="twitter-comment">Twitter Comment</option> 
                                        <option value="instagram-follow">Instagram Follow</option> 
                                        <option value="instagram-like">Instagram Like</option> 
                                        <option value="instagram-view">Instagram View</option> 
                                        <option value="instagram-comment">Instagram Comment</option> 
                                        <option value="youtube-subscribe">Youtube Subscribe</option> 
                                        <option value="youtube-view">Youtube View</option> 
                                        <option value="youtube-like">Youtube Like</option> 
                                        <option value="youtube-comment">Youtube Comment</option> 
                                        <option value="website-vote">Website Vote</option> 
                                        <option value="tiktok-like">TikTok Like</option> 
                                        <option value="tiktok-follow">TikTok Follow</option> 
                                        <option value="tiktok-comment">TikTok Comment</option> 
                                        <option value="tiktok-view">TikTok View</option> 
                                        <option value="tiktok-save">TikTok Save</option>
                                        <option value="threads-follow">Threads Follow</option> 
                                        <option value="threads-like">Threads Like</option> 
                                        <option value="threads-view">Threads View</option> 
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="country">Country</label>
                                    <div class="d-flex">
                                        <label class="mr-3">
                                            <input v-model="nigeria" type="checkbox" id="nigeria" class="" required>
                                            Nigeria
                                        </label>
                                        <label class="mr-3">
                                            <input v-model="ghana" type="checkbox" id="ghana" class="" required>
                                            Ghana
                                        </label>
                                        <label class="mr-3">
                                            <input v-model="kenya" type="checkbox" id="kenya" class="" required>
                                            Kenya
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="target">Target</label>
                                    <input v-model="target" type="text" id="target" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="link">Link</label>
                                    <input v-model="link" type="text" id="link" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="show_comment == 'true'">
                                <div class="form-group">
                                    <label class="form-control-label" for="comment">Comment</label>
                                    <input v-model="comment" type="text" id="comment" class="form-control" required>
                                </div>
                            </div>
                        </div>
                        <div>
    
                            <button @click="createAdvert" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                                Create Advert
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        ExportToCsv
    } from "export-to-csv"
    import ApiService from '@/services/api_service'
    
    export default {
        name: "CreateAdverts",
        components: {},
        data: () => ({
            loading: false,
            platform_type: '',
            platform: '',
            type: '',
            target: '',
            link: '',
            comment: '',
            nigeria: '',
            ghana: '',
            kenya: '',
            show_comment: 'false',
            data: {}
        }),
        methods: {
            setPlatformType(){
                let platform_type = this.platform_type
                if(platform_type){
                    let platformTypeSplit = platform_type.split('-')
                    if(platformTypeSplit.length != 2){
                        this.notifyUser('error', 'Platform and type not understood')
                    }else{
                        this.platform = platformTypeSplit[0]
                        this.type = platformTypeSplit[1]
                    }
                    if(platform_type.search('comment') > -1 || platform_type.search('vote') > -1){
                        this.show_comment = 'true'
                    }else[
                        this.show_comment = 'false'
                    ]
                }else{
                    this.notifyUser('error', 'Platform type is required')
                }
            },
            async createAdvert() {
                this.loading = true
                try {
                    let data = {
                        'platform': this.platform,
                        'type': this.type,
                        'target': this.target,
                        'link': this.link,
                        'comment': this.comment,
                        'nigeria': this.nigeria,
                        'ghana': this.ghana,
                        'kenya': this.kenya,
                    }
                    var apiResponse = await ApiService.SaveAdvert(data)
                    if (apiResponse['status'] == 'success') {
                        this.platform_type = ''
                        this.platform = ''
                        this.type = ''
                        this.target = ''
                        this.link = ''
                        this.comment = ''
                        this.nigeria = ''
                        this.ghana = ''
                        this.kenya = ''
                    } else {}
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            },
        },
        computed: {
            tableHeadings() {
                return []
            }
        },
        mounted() {},
        watch: {}
    }
    </script>
    
    <style scoped>
        </style>
    