<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="postsData['total_page']">{{ (parseInt(postsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{postsData['total_posts'].toLocaleString()}} Posts</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchPosts" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User
                                </th>
                                <th scope="col">
                                    Category
                                </th>
                                <th scope="col">
                                    Topic
                                </th>
                                <th scope="col">
                                    Total Comments
                                </th>
                                <th scope="col">
                                    Page Views
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allPosts" :key="item.id">
                                <td>
                                    <span v-if="item['user']">
                                        {{ item['user']['name'] }}<br />
                                        {{ item['user']['email'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['category'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['topic'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['total_comments'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['page_views'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['status'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/post/'+item.id" class="btn btn-sm btn-icon btn-primary">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Posts",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allPostsData: [],
        allPosts: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        postsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_posts_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Posts",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allPosts)
            this.exporting = false
        },
        async getPosts() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetPosts(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.postsData = apiResponse['data']
                    this.allPosts = this.postsData['posts']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchPosts() {
            this.page = 0
            this.getPosts()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getPosts()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.postsData['total_page']) {
                this.page = this.page + 1
                this.getPosts()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getPosts();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "topic",
                "user_id",
                "email",
                "category",
                "page_views",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getPosts()
    },
    watch: {}
}
</script>

<style scoped>
</style>
