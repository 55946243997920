<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="pagination['last_page']">{{ parseInt(pagination['last_page']).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading && pagination.length > 0" class="mb-0">{{pagination['total'].toLocaleString()}} Users</h3>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                        <div class="col-old d-none">
                            <button @click="exportToCSV" class="btn btn-sm btn-icon btn-primary float-right" type="button" :data-loading="exporting" :disabled="exporting">
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">User</th>
                                <th scope="col">Document</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in applications" :key="item.id">
                                <td>
                                    {{ item['id'] }}
                                </td>
                                <td>
                                    <p v-if="item['user']">
                                        <a :href="'/dashboard/user/'+item['user']['id']">
                                            {{ item['user']['name'] }}
                                        </a>
                                    </p>
                                </td>
                                <td>
                                    <p v-if="item['document']">
                                        <a :href="item['document']">
                                            {{ item['document'] }}
                                        </a>
                                    </p>
                                </td>
                                <td class="text-right">
                                    <div class="mb-2">
                                        <router-link target="_blank" :to="vtSocial()+'/dashboard/timeline/' + item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                            <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                    <div v-if="item.status == 'active'">
                                        <button class="btn btn-danger" @click="unverifyUserApplication(item.id)">
                                            unverify
                                        </button>
                                        <button class="btn btn-primary" @click="verifyUserApplication(item.id)">
                                            verify
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "UserVerificationApplications",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        applications: [],
        pagination: '',
        fields: [
            "user_id",
            "status",
            "created_at",
            "updated_at"
        ],
        page: 0,
        usersData: [],
        total_page: 0,
        current_page: 0,
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_users_applications_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Users",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.users)
            this.exporting = false
        },
        async getUserVerificationApplications() {
            this.loading = true
            try {
                var apiResponse = await ApiService.UserVerificationApplications()
                if (apiResponse['status'] == 'success') {
                    this.usersData = apiResponse['data']
                    this.applications = this.usersData['applications']
                    this.pagination = this.usersData['pagination']
                    this.total_page = apiResponse['data']['pagination']["last_page"];
                    this.current_page = apiResponse['data']['pagination']["current_page"];
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async verifyUserApplication(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.VerifyUserApplication(id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    setTimeout(() => {
                        location.href = ''
                    }, 1300);
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async unverifyUserApplication(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.UnverifyUserApplication(id)
                this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    setTimeout(() => {
                        location.href = ''
                    }, 1300);
                } else {}
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getUserVerificationApplications()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.usersData['total_page']) {
                this.page = this.page + 1
                this.getUserVerificationApplications()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getUserVerificationApplications();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "status"
            ]
        }
    },
    mounted() {
        this.getUserVerificationApplications()
    },
    watch: {}
}
</script>

<style scoped>
</style>
