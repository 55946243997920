<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-flex">
                        <p class="mr-3" v-if="advertsData['total_page']">{{ (parseInt(advertsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                        <p v-if="stats">
                            {{ (parseInt(stats['unpaid'])).toLocaleString() }} Unpaid, 
                            {{ (parseInt(stats['active'])).toLocaleString() }} Running,
                            {{ (parseInt(stats['paused'])).toLocaleString() }} Paused,
                            {{ (parseInt(stats['cancelled'])).toLocaleString() }} Cancelled
                        </p>
                    </div>
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 v-if="!loading" class="mb-0">{{advertsData['total_adverts'].toLocaleString()}} Adverts</h3>
                            <a href="/dashboard/create-advert" class="ml-4 btn btn-success text-white">Create Advert</a>
                            <button v-if="currentUser['email'] == 'calmpress@gmail.com' || currentUser['email'] == 'calmpress+one@gmail.com'" @click="pauseAllAdverts" class="ml-4 btn btn-primary text-white">Pause All Adverts</button>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchAdverts" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User Email
                                </th>
                                <th scope="col">
                                    Advert Details
                                </th>
                                <th scope="col">
                                    Link
                                </th>
                                <th scope="col">
                                    Target
                                </th>
                                <th scope="col">
                                    Pending Approval
                                </th>
                                <th scope="col">
                                    Created
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">
                                    Progress
                                </th>
                                <th scope="col">
                                    Comment
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allAdverts" :key="item.id">
                                <td>
                                    <span v-if="item['user']">
                                        {{ item['user']['email'] }}
                                    </span>
                                </td>
                                <td>
                                    <span class="text-capitalize">
                                        {{ item['platform'] }}<br />
                                        {{ item['type'] }}<br />
                                        {{ item['countries'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['url'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['users_needed'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['tasks_pending_approval'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['created_at'] }}
                                    </span>
                                </td>
                                <td>
                                    <p>
                                        <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'active' || item['status'] == 'pending_approval'" class="badge badge-primary">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'paused'" class="badge badge-info">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'pending'" class="badge badge-danger">{{ item['status'] }}</span>
                                        <span v-else class="badge badge-secondart">{{ item['status'] }}</span>
                                    </p>
                                </td>
                                <td>
                                    <progress :value="advertProgress(item['users_needed'],item['users_completed'])" max="100">{{ advertProgress(item['users_needed'],item['users_completed']) }}%</progress>
                                </td>
                                <td>
                                    <span>
                                        {{ item['comment'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <a class="btn btn-primary" :href="'/dashboard/advert/'+item.id">view</a>
                                    <button @click="manageAdvert('pause', item['id'])" v-if="item['status'] == 'active' || item['status'] == 'pending_approval'" class="btn btn-danger">pause</button>
                                    <button @click="manageAdvert('activate', item['id'])" v-if="item['status'] == 'paused'" class="btn btn-success">activate</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Adverts",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allAdvertsData: [],
        allAdverts: [],
        stats: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        advertsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_adverts_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Adverts",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allAdverts)
            this.exporting = false
        },
        async getAdverts() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetAdverts(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.advertsData = apiResponse['data']
                    this.stats = apiResponse['stats']
                    this.allAdverts = this.advertsData['adverts']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchAdverts() {
            this.page = 0
            this.getAdverts()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getAdverts()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.advertsData['total_page']) {
                this.page = this.page + 1
                this.getAdverts()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getAdverts();
        },
        advertProgress(a, b) {
            let c = 0
            a = parseFloat(a)
            b = parseFloat(b)
            if (a > 0 && b > 0) {
                c = parseInt((b / a) * 100)
            }
            return c
        },
        async manageAdvert(type, id) {
            $("button").attr('disabled', 'true')
            this.loading = true
            try {
                let data = {
                    type,
                    id
                }
                var apiResponse = await ApiService.ManageAdvert(data)
                if (apiResponse['status'] == 'success') {
                    this.getAdverts()
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
                $("button").attr('disabled', false)
            }
        },
        async pauseAllAdverts() {
            let proceed = confirm("Are you sure you want to Pause all adverts");
            if (proceed) {
                this.loading = true;
                $("button").attr('disabled', 'true')
                try {
                    var apiResponse = await ApiService.PauseAllAdverts();
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                    $("button").attr('disabled', false)
                }
            }
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "amount",
                "type",
                "platform",
                "url",
                "comment",
                "users_needed",
                "expire",
                "countries",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.currentUser = this.getUser()
        this.getAdverts()
    },
    watch: {}
}
</script>

<style scoped>
</style>
