<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col d-flex">
                                <h3 class="mb-0">Create Settings</h3>
                                <a href="/dashboard/settings" class="ml-4 btn btn-success text-white">View Settings</a>
                            </div>
                            <div class="col px-3 d-flex">
                            </div>
                        </div>
                    </div>
                    <LinearLoader v-if="loading"></LinearLoader>
                    <div class="card-header col-sm-8 m-auto">
    
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="name">Name</label>
                                    <input v-model="name" type="text" id="name" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="value">Value</label>
                                    <textarea v-model="value" type="text" id="value" class="form-control" required></textarea>
                                </div>
                            </div>
                        </div>
                        <div>
    
                            <button @click="createSetting" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        ExportToCsv
    } from "export-to-csv"
    import ApiService from '@/services/api_service'
    
    export default {
        name: "CreateSetting",
        components: {},
        data: () => ({
            loading: false,
            name: '',
            value: '',
            data: {}
        }),
        methods: {
            async createSetting() {
                this.loading = true
                try {
                    var apiResponse = await ApiService.SaveSetting(this.name, this.value)
                    if (apiResponse['status'] == 'success') {
                        this.name = ''
                        this.value = ''
                    } else {}
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            },
        },
        computed: {
            tableHeadings() {
                return []
            }
        },
        mounted() {},
        watch: {}
    }
    </script>
    
    <style scoped>
        </style>
    