<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 v-if="!loading" class="mb-0"> Brands</h3>
                <button class="btn btn-sm btn-icon btn-success" type="button" data-toggle="modal" data-target="#add-brand">Add New Brand</button>
              </div>
              <div class="col px-3 d-flex">
                <input v-model="search" class="form-control" placeholder="search with name and click enter" />
                <button
                  class="btn btn-sm btn-icon btn-success float-right"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
              <tr>
                <th
                  v-for="field in tableHeadingsBrand"
                  :key="'brand-'+field"
                  scope="col"
                >
                  {{ field.replace(/_/g, " ") }}
                </th>
                <th
                  v-for="field in userFields('mini')"
                  :key="'brand-user-'+field"
                  scope="col"
                >
                  Posted By: {{ field.replace(/_/g, " ") }}
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in brands" :key="item.id">
                <td
                  v-for="field in tableHeadingsBrand"
                  :key="'brand-'+field"
                >
                  {{ item[field] }}
                </td>
                <td
                  v-for="field in userFields('mini')"
                  :key="'brand-user-'+field"
                >
                  <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#brand-details">
                    <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-5">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 v-if="!loading" class="mb-0"> Categories</h3>
                <button class="btn btn-sm btn-icon btn-success" type="button" data-toggle="modal" data-target="#add-category">Add New Category</button>
              </div>
              <div class="col px-3 d-flex">
                <input v-model="search" class="form-control" placeholder="search with name and click enter" />
                <button
                  class="btn btn-sm btn-icon btn-success float-right"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
              <tr>
                <th
                  v-for="field in tableHeadingsCategory"
                  :key="'brand-'+field"
                  scope="col"
                >
                  {{ field.replace(/_/g, " ") }}
                </th>
                <th
                  v-for="field in userFields('mini')"
                  :key="'brand-user-'+field"
                  scope="col"
                >
                  Posted By: {{ field.replace(/_/g, " ") }}
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in categories" :key="item.id">
                <td
                  v-for="field in tableHeadingsCategory"
                  :key="'category-body-'+field"
                >
                  {{ item[field] }}
                </td>
                <td
                  v-for="field in userFields('mini')"
                  :key="'category-body-user-'+field"
                >
                  <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#category-details">
                    <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-5">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 v-if="!loading" class="mb-0"> Sub Categories</h3>
                <button class="btn btn-sm btn-icon btn-success" type="button" data-toggle="modal" data-target="#add-sub-category">Add New Sub Category</button>
              </div>
              <div class="col px-3 d-flex">
                <input v-model="search" class="form-control" placeholder="search with name and click enter" />
                <button
                  class="btn btn-sm btn-icon btn-success float-right"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
              <tr>
                <th
                  v-for="field in tableHeadingsSubCategory"
                  :key="'subcategory-'+field"
                  scope="col"
                >
                  {{ field.replace(/_/g, " ") }}
                </th>
                <th
                  v-for="field in userFields('mini')"
                  :key="'subcategory-user-'+field"
                  scope="col"
                >
                  Posted By: {{ field.replace(/_/g, " ") }}
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in sub_categories" :key="item.id">
                <td
                  v-for="field in tableHeadingsSubCategory"
                  :key="'subcategory-body-'+field"
                >
                  {{ item[field] }}
                </td>
                <td
                  v-for="field in userFields('mini')"
                  :key="'subcategory-body-user-'+field"
                >
                  <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#subcategory-details">
                    <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-5">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 v-if="!loading" class="mb-0"> Sizes</h3>
                <button class="btn btn-sm btn-icon btn-success" type="button" data-toggle="modal" data-target="#add-size">Add New Size</button>
              </div>
              <div class="col px-3 d-flex">
                <input v-model="search" class="form-control" placeholder="search with name and click enter" />
                <button
                  class="btn btn-sm btn-icon btn-success float-right"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
              <tr>
                <th
                  v-for="field in tableHeadingsSizes"
                  :key="'size-'+field"
                  scope="col"
                >
                  {{ field.replace(/_/g, " ") }}
                </th>
                <th
                  v-for="field in userFields('mini')"
                  :key="'size-user-'+field"
                  scope="col"
                >
                  Posted By: {{ field.replace(/_/g, " ") }}
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in sizes" :key="item.id">
                <td
                  v-for="field in tableHeadingsSubCategory"
                  :key="'size-body-'+field"
                >
                  {{ item[field] }}
                </td>
                <td
                  v-for="field in userFields('mini')"
                  :key="'size-body-user-'+field"
                >
                  <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                </td>
                <td class="text-right">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-5">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 v-if="!loading" class="mb-0"> Colors</h3>
                <button class="btn btn-sm btn-icon btn-success" type="button" data-toggle="modal" data-target="#add-color">Add New Color</button>
              </div>
              <div class="col px-3 d-flex">
                <input v-model="search" class="form-control" placeholder="search with name and click enter" />
                <button
                  class="btn btn-sm btn-icon btn-success float-right"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
              <tr>
                <th
                  v-for="field in tableHeadingsColors"
                  :key="'color-'+field"
                  scope="col"
                >
                  {{ field.replace(/_/g, " ") }}
                </th>
                <th
                  v-for="field in userFields('mini')"
                  :key="'color-user-'+field"
                  scope="col"
                >
                  Posted By: {{ field.replace(/_/g, " ") }}
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in colors" :key="item.id">
                <td
                  v-for="field in tableHeadingsSubCategory"
                  :key="'color-body-'+field"
                >
                  {{ item[field] }}
                </td>
                <td
                  v-for="field in userFields('mini')"
                  :key="'color-body-user-'+field"
                >
                  <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                </td>
                <td class="text-right">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <add-brand></add-brand>
      <add-category></add-category>
      <add-sub-category></add-sub-category>
      <add-sizes></add-sizes>
      <add-colors></add-colors>
    </div>
  </div>
</template>

<script>
  import { ExportToCsv } from "export-to-csv"
  import ApiService from '@/services/api_service'
  import AddBrand from '@/views/dashboard/modals/AddBrand'
  import AddCategory from '@/views/dashboard/modals/AddCategory'
  import AddSubCategory from '@/views/dashboard/modals/AddSubCategory'
  import AddSizes from '@/views/dashboard/modals/AddSizes'
  import AddColors from '@/views/dashboard/modals/AddColors'

  export default {
    name: "ProductsExtraInfo",
    components: {
        AddBrand,
        AddCategory,
        AddSubCategory,
        AddSizes,
        AddColors
    },
    data: () => ({
      exporting: false,
      loading: true,
      selectedId: null,
      defFields:[],
      search:"",
      searchResult:[],
      fields: [
      ],
      page: 0,
      sizes:[],
      colors:[],
      brands:[],
      categories:[],
      sub_categories:[]
    }),
    methods: {
      async getProductExtraInfos () {
        this.loading = true
        try {
          var apiResponse = await ApiService.GetProductExtraInfos(this.page, this.search)
          if(apiResponse['status'] == 'success'){
            this.brands = apiResponse['data']['brands']
            this.categories = apiResponse['data']['categories']
            this.sub_categories = apiResponse['data']['sub_categories']
            this.sizes = apiResponse['data']['sizes']
            this.colors = apiResponse['data']['colors']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
    },
    computed: {
      tableHeadingsBrand () {
        return [
          "id",
          "user_id",
          "name",
          "status",
          "created_at",
          "updated_at"
        ]
      },
      tableHeadingsCategory () {
        return [
          "id",
          "user_id",
          "name",
          "status",
          "created_at",
          "updated_at"
        ]
      },
      tableHeadingsSubCategory () {
        return [
          "id",
          "user_id",
          "name",
          "category_id",
          "status",
          "created_at",
          "updated_at"
        ]
      },
      tableHeadingsSizes () {
        return [
          "id",
          "user_id",
          "name",
          "status",
          "created_at",
          "updated_at"
        ]
      },
      tableHeadingsColors () {
        return [
          "id",
          "user_id",
          "name",
          "status",
          "created_at",
          "updated_at"
        ]
      }
    },
    mounted () {
      this.getProductExtraInfos()
    },
    watch: {
    }
  }
</script>

<style scoped>
</style>
