<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" v-if="user.user">Name: {{ user.user.name }}</h3>
                        </div>
                        <div class="col text-right">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div v-if="user" class="row p-3">
                    <div v-if="user.user" class="col-xl-6 col-md-6 col-sm-12">

                        <div class="mt-2">
                            <h3 class="card-title text-uppercase text-muted mb-2">User Info</h3>
                            <p class="h4 font-weight-bold">Name: {{ user.user.name }}</p>
                            <p class="">Email: {{ user.user.email }}</p>
                            <p class="">Username: {{ user.user.username }}</p>
                            <p class="" v-if="user.user.type">
                                Type: 
                                <span class="badge badge-info">{{ user.user.type }}</span>
                                <span class="ml-4">
                                    <button @click="changeUserType(user.user.id, 'promoter', user.user.type)" v-if="user.user.type.toLowerCase() != 'promoter'" class="btn btn-sm btn-info">make promoter</button>
                                </span>
                                <span class="ml-4">
                                    <button @click="changeUserType(user.user.id, 'advertiser', user.user.type)" v-if="user.user.type.toLowerCase() != 'advertiser'" class="btn btn-sm btn-primary">make advertiser</button>
                                </span>
                            </p>
                            <p class="">DOB: {{ user.user.dob }}</p>
                            <p class="">Phone: {{ user.user.phone }}</p>
                            <p class="">Country: {{ user.user.country }}</p>
                            <p class="">State: {{ user.user.state }}</p>
                            <p class="">Gender: {{ user.user.gender }}</p>
                            <p class="">IP: {{ user.user.ip }}</p>
                            <p class="">Browser: {{ user.user.browser }}</p>
                            <p class="">Verified: 
                                <span v-if="user.user.verified == 'true'" class="badge badge-success">{{ user.user.verified }}</span>
                                <span v-else class="badge badge-danger">{{ user.user.verified }}</span>
                            </p>
                            <p class="">Online: 
                                <span v-if="user.user.online == 'true'" class="badge badge-success">{{ user.user.online }}</span>
                                <span v-else class="badge badge-danger">{{ user.user.online }}</span>
                            </p>
                            <p class="">Last seen: {{ unixToUtc(user.user.last_seen) }}</p>
                            <p class="">Last Login: {{ user.user.last_login }}</p>
                            <p class="">Blog Posts: {{ user.user.blog_posts }}</p>
                            <p class="">Forum Posts: {{ user.user.forum_posts }}</p>
                            <p class="">Bio: {{ user.user.bio }}</p>
                            <!-- <p class="">
                                Phone verified:
                                <span v-if="!user.user.phone_number_verified_at">No</span>
                                <span v-else>Yes</span>
                            </p>
                            <p class="">
                                Email verified:
                                <span v-if="!user.user.email_verified_at">No</span>
                                <span v-else>Yes</span>
                            </p> -->
                            <p class="">
                                Status: 
                                <span v-if="user.user.status == 'active'" class="badge badge-success">{{ user.user.status }}</span>
                                <span v-else-if="user.user.status == 'new'" class="badge badge-warning">{{ user.user.status }}</span>
                                <span v-else class="badge badge-default">{{ user.user.status }}</span>
                            </p>
                            <p class="">Created at: {{ user.user.created_at }}</p>
                            <p class="">Updated at: {{ user.user.updated_at }}</p>
                            <div class="mt-3" v-if="currentUser.email == 'calmpress@gmail.com' || currentUser.email == 'calmpress+one@gmail.com'">
                                <button v-if="!user.user.role" class="btn btn-primary" @click="makeAdmin(user.user.id)">
                                    make admin
                                </button>
                                <button v-else class="btn btn-danger" @click="removeAdmin(user.user.id)">
                                    remove admin
                                </button>
                            </div>
                            <div class="mt-3">
                                <button v-if="user.user.status == 'deactive'" class="btn btn-danger" @click="unbanUser(user.user.id)">
                                    unban
                                </button>
                                <button v-else-if="user.user.status == 'new'" class="btn btn-primary" @click="activateUser(user.user.id)">
                                    activate
                                </button>
                                <button v-else class="btn btn-success" @click="banUser(user.user.id)">
                                    ban
                                </button>
                            </div>
                            <div class="mt-3">
                                <button v-if="user.user.verified == 'true'" class="btn btn-danger" @click="unverifyUser(user.user.id)">
                                    unverify
                                </button>
                                <button v-else class="btn btn-primary" @click="verifyUser(user.user.id)">
                                    verify
                                </button>
                            </div>
                            <p class="mb-5"></p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12">
                        <div v-if="user.wallet">
                            <h3 class="card-title text-uppercase text-muted mb-2">Wallet Balances</h3>
                            <div class="row">
                                <div class="col">
                                    <p>Amount: {{ currency }}{{ parseFloat(user.wallet.amount).toLocaleString() }}</p>
                                </div>
                                <div class="col">
                                    <p>Pending Balance: {{ currency }}{{ parseFloat(pending_balance).toLocaleString() }}</p>
                                </div>
                                <!-- <div class="col">
                                    <p>Grant Amount: {{ currency }}{{ parseFloat(user.wallet.grant_amount).toLocaleString() }}</p>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div class="mb-2">
                                            <label class="form-control-label" for="name">Amount({{ currency }}):</label>
                                            <input v-model="amount_credit" type="text" id="amount_credit" class="form-control" required placeholder="amount to credit">
                                        </div>
                                    </div>
                                    <p>
                                        <button @click="creditUserWallet" class="btn btn-success">Credit User Wallet</button>
                                    </p>
                                </div>
                                <div class="col">
                                    <div>
                                        <div class="mb-2">
                                            <label class="form-control-label" for="name">Amount({{ currency }}):</label>
                                            <input v-model="amount_debit" type="text" id="amount_debit" class="form-control" required placeholder="amount to debit">
                                        </div>
                                    </div>
                                    <p>
                                        <button @click="debitUserWallet" class="btn btn-danger">Debit User Wallet</button>
                                    </p>
                                </div>
                            </div>
                            <template v-if="user.user.country && user.wallet">
                                <h3 class="card-title text-uppercase text-muted mb-2">Vitisocial Wallet Balances</h3>
                                <div class="row">
                                    <div class="col" v-if="user.wallet.usd">
                                        <p>USD: {{ currency }}{{ parseFloat(user.wallet.usd).toLocaleString() }}</p>
                                        <p v-if="user.user.country.toLowerCase() != 'nigeria' && user.user.country.toLowerCase() != 'ghana' && user.user.country.toLowerCase() != 'kenya'">
                                            <span class="badge badge-info">This is what the user see's</span>
                                        </p>
                                    </div>
                                    <div class="col" v-if="user.wallet.ngn">
                                        <p>NGN: {{ currency }}{{ parseFloat(user.wallet.ngn).toLocaleString() }}</p>
                                        <p v-if="user.user.country.toLowerCase() == 'nigeria'">
                                            <span class="badge badge-info">This is what the user see's</span>
                                        </p>
                                    </div>
                                    <div class="col" v-if="user.wallet.cedi">
                                        <p>Cedi: {{ currency }}{{ parseFloat(user.wallet.cedi).toLocaleString() }}</p>
                                        <p v-if="user.user.country.toLowerCase() == 'ghana'">
                                            <span class="badge badge-info">This is what the user see's</span>
                                        </p>
                                    </div>
                                    <div class="col" v-if="user.wallet.shilling">
                                        <p>Shilling: {{ currency }}{{ parseFloat(user.wallet.shilling).toLocaleString() }}</p>
                                        <p v-if="user.user.country.toLowerCase() == 'kenya'">
                                            <span class="badge badge-info">This is what the user see's</span>
                                        </p>
                                    </div>
                                </div>
                                <template v-if="user.user['email'] == 'ceo@viraltrend.org'">
                                    <h3 class="card-title text-uppercase text-muted mb-2">Credit/Debit Vitisocial Wallet</h3>
                                    <div class="row">
                                        <div class="col">
                                            <div>
                                                <div class="mb-2">
                                                    <label class="form-control-label" for="name">Amount({{ currency }}):</label>
                                                    <input v-model="amount_credit" type="text" id="amount_credit" class="form-control" required placeholder="amount to credit">
                                                </div>
                                            </div>
                                            <p>
                                                <button @click="creditUserVitisocialWallet" class="btn btn-success">Credit User Wallet</button>
                                            </p>
                                        </div>
                                        <div class="col">
                                            <div>
                                                <div class="mb-2">
                                                    <label class="form-control-label" for="name">Amount({{ currency }}):</label>
                                                    <input v-model="amount_debit" type="text" id="amount_debit" class="form-control" required placeholder="amount to debit">
                                                </div>
                                            </div>
                                            <p>
                                                <button @click="debitUserVitisocialWallet" class="btn btn-danger">Debit User Wallet</button>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <h5 class="card-title text-uppercase text-muted mb-2">Stats</h5>
                        <div class="row">
                            <div class="col">
                                <p>Transactions: {{ user.transactions.toLocaleString() }}</p>
                            </div>
                            <div class="col">
                                <p>All Tasks: {{ user.all_tasks.toLocaleString() }}</p>
                            </div>
                            <div class="col">
                                <p>Completed Tasks: {{ user.completed_tasks.toLocaleString() }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p>
                                    <a :href="'/dashboard/user-tasks/all/'+user.user.id">view all user tasks</a>
                                </p>
                            </div>
                            <div class="col">
                                <a :href="'/dashboard/user-tasks/completed/'+user.user.id">view all completed tasks</a>
                            </div>
                        </div>
                        <div>
                            <h3 class="card-title text-uppercase text-muted mb-2">Social Handles</h3>
                            <div class="" v-if="user.social">
                                <div class="">
                                    <p v-if="social.facebook">
                                        Facebook: {{ social.facebook }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('facebook')">disconnect</span>
                                    </p>
                                    <p v-if="social.twitter">
                                        Twitter: {{ social.twitter }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('twitter')">disconnect</span>
                                    </p>
                                    <p v-if="social.instagram">
                                        Instagram: {{ social.instagram }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('instagram')">disconnect</span>
                                    </p>
                                    <p v-if="social.youtube">
                                        Youtube: {{ social.youtube }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('youtube')">disconnect</span>
                                    </p>
                                    <p v-if="social.tiktok">
                                        Tiktok: {{ social.tiktok }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('tiktok')">disconnect</span>
                                    </p>
                                    <p v-if="social.threads">
                                        Threads: {{ social.threads }}
                                        <span class="mouse-pointer text-blue ml-2" @click="clearSocialHandle('threads')">disconnect</span>
                                    </p>
                                </div>
                            </div>
                            <p v-else>User has not added any social handle yet</p>
                        </div>
                        <div>
                            <h3 class="card-title text-uppercase text-muted mb-2">Bank Account</h3>
                            <div class="" v-if="account">
                                <div class="">
                                    <p v-if="account.name">
                                        Account Name: {{ account.name }}
                                    </p>
                                    <p v-if="account.account_no">
                                        Account Number: {{ account.account_no }}
                                    </p>
                                    <p v-if="account.bank">
                                        Account Bank: {{ account.bank }}
                                    </p>
                                    <p v-if="account.account_type">
                                        Account Type: {{ account.account_type }}
                                    </p>
                                    <p>
                                        <button @click="deleteUserBankAccount" class="btn btn-danger">Delete User Bank Account</button>
                                    </p>
                                </div>
                            </div>
                            <p v-else>User has not added a bank account yet</p>
                        </div>
                        <div>
                            <h3 class="card-title text-uppercase text-muted mb-2">Subscription Plan</h3>
                            <div class="" v-if="subscription">
                                <div class="">
                                    <p v-if="subscription.plan">
                                        Plan Name: {{ subscription.plan }}
                                    </p>
                                    <p v-if="subscription.amount">
                                        Amount: {{ subscription.amount }}
                                    </p>
                                    <p v-if="subscription.created_at">
                                        Date: {{ subscription.created_at }}
                                    </p>
                                </div>
                                <p>
                                    <button @click="cancelSubscription(user.user.id, subscription.plan)" class="btn btn-danger text-capitalize">Cancel {{ subscription.plan }} Subscription</button>
                                </p>
                            </div>
                            <div v-else>
                                <p>User has not subscribed yet</p>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <button @click="manualSubscription(user.user.id, 'basic')" class="btn btn-primary">Enable Basic Subscription</button>
                                        </p>
                                    </div>
                                    <div class="col">
                                        <p>
                                            <button @click="manualSubscription(user.user.id, 'premium')" class="btn btn-primary">Enable Premium Subscription</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 class="card-title text-uppercase text-muted mb-2">Update User</h3>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="mb-2">
                                        <label class="form-control-label" for="name">Name:</label>
                                        <input type="text" id="name" class="form-control" :value="user.user.name" required placeholder="name of user">
                                    </div>
                                </div>
                                <p>
                                    <button @click="updateUser" class="btn btn-success">Update Name</button>
                                </p>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="mb-2">
                                        <label class="form-control-label" for="name">Username:</label>
                                        <input type="text" id="username" class="form-control" :value="user.user.username" required placeholder="username of user">
                                    </div>
                                </div>
                                <p>
                                    <button @click="updateUser" class="btn btn-success">Update Username</button>
                                </p>
                            </div>
                        </div>
                        <div class="row" v-if="user.user_stats">
                            <div class="col-sm-12">
                                <h3 class="card-title text-uppercase text-muted mb-2">Report of User</h3>
                            </div>
                            <div class="col-sm-12">
                                <p>
                                    withdrawals_total: {{ currencySymbol(user.currency) }}{{ user.user_stats.withdrawals_total }}<br/>
                                    user_tasks_total: {{ currencySymbol(user.currency) }}{{ user.user_stats.user_tasks_total }}<br/>
                                    subscription_amount: {{ currencySymbol(user.currency) }}{{ user.user_stats.subscription_amount }}<br/>
                                    transactions_total: {{ currencySymbol(user.currency) }}{{ user.user_stats.transactions_total }}<br/>
                                    admin_credit_total: {{ currencySymbol(user.currency) }}{{ user.user_stats.admin_credit_total }}<br/>
                                    inflow: {{ currencySymbol(user.currency) }}{{ user.user_stats.inflow }}<br/>
                                    outflow: {{ currencySymbol(user.currency) }}{{ user.user_stats.outflow }}<br/>
                                    balance: {{ currencySymbol(user.currency) }}{{ user.user_stats.balance }}<br/>
                                    outflow+balance: {{ currencySymbol(user.currency) }}{{ add(user.user_stats.outflow,user.user_stats.balance) }}<br/>
                                    inflow-outflow+balance: {{ currencySymbol(user.currency) }}{{ subtract(user.user_stats.inflow, user.user_stats.outflow,user.user_stats.balance) }}<br/>
                                    user_tasks_total-outflow: {{ currencySymbol(user.currency) }}{{ subtract(user.user_stats.user_tasks_total, user.user_stats.outflow,0) }}<br/>
                                    user_tasks_total-outflow+balance: {{ currencySymbol(user.currency) }}{{ subtract(user.user_stats.user_tasks_total, user.user_stats.outflow,user.user_stats.balance) }}<br/>
                                    user_tasks: {{ user.user_stats.user_tasks }}<br/>
                                    country: <span class="badge badge-info">{{ user.user_stats.country }}</span><br/>
                                    subscription_name: <span class="badge badge-primary">{{ user.user_stats.subscription_name }}</span><br/>
                                    status: 
                                    <span class="badge badge-success" v-if="user.user_stats.status == 'ok'">{{ user.user_stats.status }}</span>
                                    <span class="badge badge-danger" v-else>{{ user.user_stats.status }}</span>
                                    <br/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <h3 class="card-title text-uppercase text-muted mb-2">
                            Withdrawals
                            <a class="btn btn-info" :href="'/dashboard/user/withdrawals/'+user.user['id']">view all</a>
                        </h3>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <th scope="col">User Email</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">VT Name</th>
                                    <th scope="col">Bank Details</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Request Date</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Action</th>
                                </thead>
                                <tbody>
                                    <tr v-for="item in user.withdrawals" :key="item.id">
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['email'] }}</span>
                                        </td>
                                        <td>
                                            {{ parseFloat(item['amount']).toLocaleString(2) }}
                                            <p>{{ currency }} {{ calculateAmountToPay(item['amount'], fee) }}</p>
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['name'] }}</span><br/>
                                            <span v-if="item['account']">{{ item['account']['account_no'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['bank'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['account_type'] }}</span>
                                        </td>
                                        <td>
                                            {{ item['created_at'] }}
                                            <p>
                                                <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                                <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                                <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                            </p>
                                            {{ item['updated_at'] }}<br/>
                                            {{ item['updated_by'] }}
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                        </td>
                                        <td class="text-right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <h3 class="card-title text-uppercase text-muted mb-2">
                            Vitisocial Withdrawals
                            <a class="btn btn-info" :href="'/dashboard/user/vitisocial-withdrawals/'+user.user['id']">view all</a>
                        </h3>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <th scope="col">User Email</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">VT Name</th>
                                    <th scope="col">Bank Details</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Request Date</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Action</th>
                                </thead>
                                <tbody>
                                    <tr v-for="item in user.withdrawals_social" :key="item.id">
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['email'] }}</span>
                                        </td>
                                        <td>
                                            {{ parseFloat(item['amount']).toLocaleString(2) }}
                                            <p>{{ currency }} {{ calculateAmountToPay(item['amount'], fee) }}</p>
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['name'] }}</span><br/>
                                            <span v-if="item['account']">{{ item['account']['account_no'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['bank'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['account']">{{ item['account']['account_type'] }}</span>
                                        </td>
                                        <td>
                                            {{ item['created_at'] }}
                                            <p>
                                                <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                                <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                                <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                            </p>
                                            {{ item['updated_at'] }}<br/>
                                            {{ item['updated_by'] }}
                                        </td>
                                        <td>
                                            <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                        </td>
                                        <td class="text-right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div v-if="user.referred_by" class="my-3">
                            <h3 class="card-title text-uppercase text-muted mb-2"></h3>
                            <div class="row">
                                <div class="col">
                                    <p>
                                        Referred By:
                                        <span class="badge badge-info"> {{ user.referred_by.email }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h3 class="card-title text-uppercase text-muted mb-2">
                            Referrals
                            <a class="btn btn-info" :href="'/dashboard/user/referrals/'+user.user['id']">view all</a>
                        </h3>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <th v-for="field in tableHeadings" :key="'table_'+field" scope="col">
                                        {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th scope="col"></th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,key) in user.referrals" :key="key">
                                        <td v-for="field in tableHeadings" :key="field">
                                            <template v-if="field == 'id'">
                                                <a :href="'/dashboard/user/'+item[field]">
                                                    {{ item[field] }}
                                                </a>
                                            </template>
                                            <template v-else-if="field == 'status'">
                                                <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                                <span v-else-if="item[field] == 'new'" class="badge badge-warning">{{ item[field] }}</span>
                                                <span v-else class="badge badge-default">{{ item[field] }}</span>
                                            </template>
                                            <template v-else-if="field == 'type'">
                                                <span class="badge badge-info">{{ item[field] }}</span>
                                            </template>
                                            <template v-else>
                                                {{ item[field] }}
                                            </template>
                                        </td>
                                        <td class="text-right">
                                            <router-link :to="'/dashboard/user/' + item.id" class="btn btn-sm btn-icon btn-primary" >
                                                <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <h3 class="card-title text-uppercase text-muted mb-2">
                            Multiple Earnings
                            <a class="btn btn-info" :href="'/dashboard/user/multiple-earnings/'+user.user['id']">view all</a>
                        </h3>
                        <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <th scope="col">Platform</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Times Earned</th>
                                    <th scope="col">Funds Collected</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </thead>
                                <tbody>
                                    <tr v-for="item in user.tasks_multiple" :key="item.id">
                                        <td>
                                            <span v-if="item['platform']">{{ item['platform'] }}</span>
                                        </td>
                                        <td>
                                            {{ currency }} {{ parseFloat(item['amount']).toLocaleString(2) }}
                                        </td>
                                        <td>
                                            <span v-if="item['type']">{{ item['type'] }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item['times_earned']">{{ item['times_earned'] }}</span>
                                        </td>
                                        <td>
                                            <p>
                                                <span v-if="item['user_debited'] == 'yes'" class="badge badge-success">{{ item['user_debited'] }}</span>
                                                <span v-else class="badge badge-danger">{{ item['user_debited'] }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            {{ item['created_at'] }}
                                            <p>
                                                <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                                <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                                <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                            </p>
                                            {{ item['updated_at'] }}<br/>
                                            {{ item['updated_by'] }}
                                        </td>
                                        <td class="text-right">
                                            <router-link :to="'/dashboard/advert/' + item.task_id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                                <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApiService from '@/services/api_service'

export default {
    name: "User",
    components: {},
    data: () => ({
        selectedItem: null,
        id: "",
        message: "",
        loading: "",
        user: "",
        social: "",
        account: "",
        subscription: "",
        fee: "",
        amount_credit: "",
        amount_debit: "",
        currency: "NGN",
        currentUser: '',
        pending_balance: '',
        fields: [],
        withdrawalsData: [],
        withdrawal_fields: [
            "id",
            "user_id",
            "account_id",
            "amount",
            "wallet",
            "country",
            "status",
            "created_at"
        ]
    }),
    methods: {
        async getCurrentUser() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetUserSimple(this.id)
                if (apiResponse['status'] == 'success') {
                    this.user = apiResponse['data']
                    this.withdrawalsData = apiResponse["data"];
                    if(this.withdrawalsData["fee"] && this.withdrawalsData["fee"]['value']){
                        this.fee = this.withdrawalsData["fee"]['value'];
                    }
                    this.social = apiResponse['data']['social']
                    this.account = apiResponse['data']['account']
                    this.subscription = apiResponse['data']['subscription']
                    this.pending_balance = apiResponse['data']['pending_balance']
                    if(this.user.user.country){
                        if(this.user.user.country.toLowerCase() == 'ghana'){
                            this.currency = 'Cedi'
                        }else if(this.user.user.country.toLowerCase() == 'kenya'){
                            this.currency = 'Shilling'
                        }
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async clearSocialHandle(type) {
            let proceed = confirm("Are you sure you want to Clear? This will disconnect " + this.replaceAll(type, '_', ' ')+" for this user");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.ClearUserSocialHandle(this.user.user.id, type)
                    if (apiResponse['status'] == 'success') {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async deleteUserBankAccount() {
            let proceed = confirm("Are you sure you want to Clear? This will delete this users bank account ");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.DeleteUserBankAccount(this.user.user.id)
                    if (apiResponse['status'] == 'success') {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async creditUserWallet() {
            let proceed = confirm("Are you sure you want to Credit? This will wallet will be credited ");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.CreditUserWallet(this.user.user.id, this.amount_credit)
                    if (apiResponse['status'] == 'success') {
                        this.amount_credit = ''
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async debitUserWallet() {
            let proceed = confirm("Are you sure you want to Debit? This will wallet will be debited ");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.DebitUserWallet(this.user.user.id, this.amount_debit)
                    if (apiResponse['status'] == 'success') {
                        this.amount_debit = ''
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async creditUserVitisocialWallet() {
            let proceed = confirm("Are you sure you want to Credit? This will wallet will be credited ");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.CreditUserVitisocialWallet(this.user.user.id, this.amount_credit)
                    if (apiResponse['status'] == 'success') {
                        this.amount_credit = ''
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async debitUserVitisocialWallet() {
            let proceed = confirm("Are you sure you want to Debit? This will wallet will be debited ");
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.DebitUserVitisocialWallet(this.user.user.id, this.amount_debit)
                    if (apiResponse['status'] == 'success') {
                        this.amount_debit = ''
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                        this.getCurrentUser()
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                    // window.alert(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        async verifyUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.VerifyUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async unverifyUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.UnverifyUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async banUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.BanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async activateUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.ActivateUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async unbanUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.UnbanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async makeAdmin(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.MakeUserAdmin(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async removeAdmin(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.RemoveUserAdmin(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async manualSubscription(id, plan) {
            let proceed = confirm("Are you sure you want to Enable " + plan + ' subscription for this user');
            if (proceed) {
                this.loading = true;
                $("button").attr('disabled', 'true')
                try {
                    var apiResponse = await ApiService.ManualSubscriptionApi(id, plan);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                    $("button").attr('disabled', false)
                }
            }
        },
        async cancelSubscription(id, plan) {
            let proceed = confirm("Are you sure you want to Cancel " + plan + ' subscription for this user');
            if (proceed) {
                this.loading = true;
                $("button").attr('disabled', 'true')
                try {
                    var apiResponse = await ApiService.CancelSubscriptionApi(id);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                    $("button").attr('disabled', false)
                }
            }
        },
        async updateUser() {
            let id = this.user.user.id
            let name = $("#name").val()
            let username = $("#username").val()
            if(!name){
                this.notifyUser("error", 'name is required');
                return 
            }
            if(!username){
                this.notifyUser("error", 'username is required');
                return 
            }
            let proceed = confirm("Are you sure you want to Update this user");
            if (proceed) {
                this.loading = true;
                $("button").attr('disabled', 'true')
                try {
                    var apiResponse = await ApiService.UpdateUserApi(id, name, username);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                    $("button").attr('disabled', false)
                }
            }
        },
        async changeUserType(id, type, current_type) {
            let proceed = confirm("Are you sure you want to change user from " + current_type + ' to '+type);
            if (proceed) {
                this.loading = true;
                $("button").attr('disabled', 'true')
                try {
                    var apiResponse = await ApiService.ChangeUserType(id, type);
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                    if (apiResponse["status"] == "success") {
                        setTimeout(() => {
                            location.href = "";
                        }, 1300);
                    } else {}
                } catch (e) {
                    let m = "something went wrong";
                    if (e) {
                        m = e["statusText"];
                    }
                    this.notifyUser("error", m);
                    // window.alert(e.message)
                } finally {
                    this.loading = false;
                    $("button").attr('disabled', false)
                }
            }
        },
        calculateAmountToPay(a, b) {
            let c = 0
            a = parseFloat(a)
            b = parseFloat(b)
            if (a > 0 && b > 0) {
                let d = ((100 - b) / 100) * a
                c = d.toLocaleString(2)
            }
            return c
        },
    },
    mounted() {
        this.currentUser = this.getUser()
        this.id = this.$route.params['id']
        this.getCurrentUser(this.id.toString())
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "username",
                "email",
                "type",
                "state",
                "country",
                "gender",
                "dob",
                "status",
                "created_at",
            ];
        },
    },
}
</script>

<style scoped>
</style>
