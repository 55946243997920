<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="notificationsData['total_page']">{{ (parseInt(notificationsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 v-if="!loading" class="mb-0">{{notificationsData['total_notys'].toLocaleString()}} Notifications</h3>
                            <a href="/dashboard/create-notification" class="ml-4 btn btn-success text-white">Create Notification</a>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchNotifications" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allNotifications" :key="item.id">
                                <td v-for="field in tableHeadings" :key="'content_'+field">
                                    <template v-if="field == 'status'">
                                        <span class="badge badge-success" v-if="item[field] == 'active'">{{ item[field] }}</span>
                                        <span class="badge badge-warning" v-else-if="item[field] == 'paused'">{{ item[field] }}</span>
                                        <span class="badge badge-danger" v-else>{{ item[field] }}</span>
                                    </template>
                                    <template v-else>
                                        {{ item[field] }}
                                    </template>
                                </td>
                                <td class="text-right">
                                    <button v-if="item['status'] == 'active'" @click="pauseNoty(item['id'])" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                                        Pause
                                    </button>
                                    <button v-if="item['status'] == 'paused'" @click="activateNoty(item['id'])" class="btn btn-sm btn-icon btn-success" type="button" :data-loading="loading" :disabled="loading">
                                        Activate
                                    </button>
                                    <button v-if="item['status'] == 'active' || item['status'] == 'paused'" @click="removeNoty(item['id'])" class="btn btn-sm btn-icon btn-danger" type="button" :data-loading="loading" :disabled="loading">
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Notifications",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allNotificationsData: [],
        allNotifications: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        notificationsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_notifications_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Notifications",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allNotifications)
            this.exporting = false
        },
        async getNotifications() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetNotifications(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.notificationsData = apiResponse['data']
                    this.allNotifications = this.notificationsData['notys']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async pauseNoty(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.PauseNoty(id)
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    this.getNotifications()
                } else {
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async activateNoty(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.ActivateNoty(id)
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    this.getNotifications()
                } else {
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async removeNoty(id) {
            this.loading = true
            try {
                var apiResponse = await ApiService.RemoveNoty(id)
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                if (apiResponse['status'] == 'success') {
                    this.getNotifications()
                } else {
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchNotifications() {
            this.page = 0
            this.getNotifications()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getNotifications()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.notificationsData['total_page']) {
                this.page = this.page + 1
                this.getNotifications()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getNotifications();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "type",
                "message",
                "link",
                "count_user",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getNotifications()
    },
    watch: {}
}
</script>

<style scoped>
</style>
