<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="jobsData['total_page']">{{ (parseInt(jobsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{jobsData['total_jobs'].toLocaleString()}} Jobs</h3>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchJobs" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    User
                                </th>
                                <th scope="col">
                                    Company
                                </th>
                                <th scope="col">
                                    Role
                                </th>
                                <th scope="col">
                                    Type
                                </th>
                                <th scope="col">
                                    Application link
                                </th>
                                <th scope="col">
                                    Salary
                                </th>
                                <th scope="col">
                                    Viewers
                                </th>
                                <th scope="col">
                                    Views
                                </th>
                                <th scope="col">
                                    Tags
                                </th>
                                <th scope="col">
                                    Image
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allJobs" :key="item.id">
                                <td>
                                    <span v-if="item['user']">
                                        {{ item['user']['name'] }}<br />
                                        {{ item['user']['email'] }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['company']">
                                        {{ item['company']['name'] }}<br />
                                        {{ item['company']['sector'] }}<br />
                                        {{ item['company']['location'] }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['job_role']">
                                        {{ item['job_role']['name'] }}<br />
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['job_type']">
                                        {{ item['job_type']['name'] }}<br />
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['application_link'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['min_salary'] }} - {{ item['max_salary'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['unique_views_count'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['views_count'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['tags'] }}
                                    </span>
                                </td>
                                <td>
                                    <a :href="item['image']" target="_blank">
                                        <img style="height:30px" :src="item['image']" />
                                    </a>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/job/'+item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Jobs",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        searchResult: [],
        allJobsData: [],
        allJobs: [],
        fields: [
            "name",
            "username",
            "email",
            "email_verified_at",
            "date_of_birth",
            "profile_image",
            "phone_number",
            "phone_number_verified_at",
            "last_login",
            "credit_score",
            "bvn",
            "country",
            "state",
            "city",
            "lga",
            "address",
            "occupation",
            "marital_status",
            "no_of_children",
            "role",
            "status",
            "created_at",
            "updated_at"
        ],
        page: 0,
        total_page: 0,
        current_page: 0,
        jobsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_jobs_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Jobs",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allJobs)
            this.exporting = false
        },
        async getAllJobs() {
            this.loading = true
            try {
                var apiResponse = await ApiService.AllJobs(this.page, this.search)
                if (apiResponse['status'] == 'success') {
                    this.jobsData = apiResponse['data']
                    this.allJobs = this.jobsData['jobs']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchJobs() {
            this.page = 0
            this.getAllJobs()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getAllJobs()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.jobsData['total_page']) {
                this.page = this.page + 1
                this.getAllJobs()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getAllJobs();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "job_role_id",
                "job_company_id",
                "job_type_id",
                "user_id",
                "min_salary",
                "max_salary",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getAllJobs()
    },
    watch: {}
}
</script>

<style scoped>
</style>
