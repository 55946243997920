<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" v-if="post">Name: {{ post.topic }}</h3>
              </div>
              <div class="col text-right">
              </div>
            </div>
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div v-if="post" class="row p-3">
                <div v-if="post" class="col-sm-12">

                    <div class="mt-2">
                        <h3 class="card-title text-uppercase text-muted mb-2">User Info</h3>
                        <p class="h4 font-weight-bold">Category: {{ post.category }}</p>
                        <p class="">Email: {{ post.email }}</p>
                        <p class="">
                            User: 
                            <a :href="'/dashboard/user/'+post.user_id">
                                {{ post.user_id }}
                            </a>
                        </p>
                        <p class="">Page Views: {{ post.page_views }}</p>
                        <p class="">Topic: {{ post.topic }}</p>
                        <div class="">
                            Status: {{ post.status }}
                            <p></p>
                            <button v-if="post.status == 'pending'" @click="approvePost" class="btn btn-success mr-3">Approve</button>
                            <button v-if="post.status == 'de'" @click="approvePostPayUser" class="btn btn-primary mr-3">Approve and Pay </button>
                            <button v-if="post.status == 'active'" @click="disapprovePost" class="btn btn-primary mr-3">Disapprove </button>
                            <button class="btn btn-danger mr-3" @click="deletePost">Delete</button>
                        </div>
                        <p class="mt-4">Post: </p>
                        <div v-html="post.post"></div>
                    </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api_service'

  export default {
    name: "Post",
    components: {
    },
    data: () => ({
      selectedItem: null,
      id: "",
      message: "",
      loading: "",
      post: "",
      fields: [
      ],
      withdrawal_fields: [
        "id",
        "user_id",
        "account_id",
        "amount",
        "wallet",
        "country",
        "status",
        "created_at"
      ]
    }),
    methods: {
      async getPost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.GetPost(this.id)
          if(apiResponse['status'] == 'success'){
            this.post = apiResponse['data']['post']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async approvePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.ApprovePost(this.id)
          if(apiResponse['status'] == 'success'){
            this.post = apiResponse['data']['post']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async approvePostPayUser () {
        this.loading = true
        try {
          var apiResponse = await ApiService.ApprovePostPayUser(this.id)
          if(apiResponse['status'] == 'success'){
            this.post = apiResponse['data']['post']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async disapprovePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.DisapprovePost(this.id)
          if(apiResponse['status'] == 'success'){
            this.post = apiResponse['data']['post']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async updatePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.UpdatePost(this.id, this.postTopic, this.postPost)
          if(apiResponse['status'] == 'success'){
            this.post = apiResponse['data']['post']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async deletePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.DeletePost(this.id)
          if(apiResponse['status'] == 'success'){
            location.href = '/dashboard/posts'
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
    },
    mounted () {
        this.id = this.$route.params['id']
        this.getPost(this.id.toString())
    }
  }
</script>

<style scoped>
</style>
