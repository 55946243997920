import store from "../../store"
import Vue from "vue"

export const isAuthenticated = (to, from, next) => {
    let accessToken = localStorage.getItem('access_token')
    let user = localStorage.getItem('user')
        // console.log('isAuthenticated', accessToken)
        // console.log('user', user)
    if (accessToken) {
        return next()
    }
    return next({
        name: "login"
    })
}

export const isNotAuthenticated = (to, from, next) => {
    let accessToken = localStorage.getItem('access_token')
    console.log('isNotAuthenticated', accessToken)
    if (!accessToken) {
        return next()
    }
    return next({
        name: "overview"
    })
}