<template>
  <div
    class="modal fade"
    id="add-brand"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add-brand"
    aria-hidden="true"
  >
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <template v-if="data">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">Add Brand</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label" for="name">Name</label>
                  <input v-model="name" type="text" id="name" class="form-control" required placeholder="name">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="save"
              type="button"
              class="btn btn-primary"
              :disabled="saving"
              :data-loading="saving"
            >
              Save
            </button>
            <button type="button" class="btn btn-link ml-auto" data-dismiss="modal">Close</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex"
  import ApiService from '@/services/api_service'

  export default {
    name: "AddBrand",
    data: () => ({
      saving: false,
      name: '',
      uploadingFile: false,
      data: {}
    }),
    methods: {
      async save () {
        this.saving = true
        try {
          const res = await ApiService.SaveProductBrand(this.name)
          this.data = {}
          this.notifyUser(res.status, res.message)
          if(res.status == 'success'){
            location.href = ''
          }
        } catch (e) {
        } finally {
          this.saving = false
        }
      }
    },
    computed: {
    }
  }
</script>

<style scoped>

</style>
