<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="reportsData['total_page']">{{ (parseInt(reportsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading" class="mb-0">{{reportsData['total_reports'].toLocaleString()}} Reports</h3>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    Type
                                </th>
                                <th scope="col">
                                    ID
                                </th>
                                <th scope="col">
                                    Reason
                                </th>
                                <th scope="col">
                                    Content
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allReports" :key="item.id">
                                <td>
                                    <span class="text-capitalize">
                                        {{ replaceAll(item['type'], '_', ' ') }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['type_id'] }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['reason'] }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item['feed']">
                                        {{ item['feed']['content'] }}
                                    </span>
                                    <span v-else-if="item['feed_comment']">
                                        {{ item['feed_comment']['comment'] }}
                                    </span>
                                    <span v-else-if="item['feed_comment_reply']">
                                        {{ item['feed_comment_reply']['reply'] }}
                                    </span>
                                    <span v-else-if="item['feed_comment_reply_reply']">
                                        {{ item['feed_comment_reply_reply']['reply'] }}
                                    </span>
                                    <span v-else>
                                        UNKNOWN
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item['status'] }}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <div class="mb-2">
                                        <router-link target="_blank" :to="vtSocial()+'/dashboard/feed/' + item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                            <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                    <div class="d-flex" v-if="item['status'] == 'active'">
                                        <div class="mr-3">
                                            <button @click="confirmApproval(item['id'], item['type'])" class="btn btn-success">Approve</button>
                                        </div>
                                        <div>
                                            <button @click="confirmRejection(item['id'], item['type'])" class="btn btn-danger">Reject</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Reports",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        section: "all",
        searchResult: [],
        allReportsData: [],
        allReports: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        reportsData: []
    }),
    methods: {
        exportToCSV() {
            this.exporting = true
            const options = {
                filename: `viraltrend_reports_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: "\"",
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Reports",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            csvExporter.generateCsv(this.allReports)
            this.exporting = false
        },
        async getReports() {
            this.loading = true
            try {
                var apiResponse = await ApiService.GetReports(this.page, this.section)
                if (apiResponse['status'] == 'success') {
                    this.reportsData = apiResponse['data']
                    this.allReports = this.reportsData['reports']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async confirmApproval(id, type) {
            let proceed = confirm("Are you sure you want to Approve? This will delete the " + this.replaceAll(type, '_', ' '));
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.ApproveReport(id)
                    if (apiResponse['status'] == 'success') {
                        location.href = ''
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                } finally {
                    this.loading = false
                }
            } else {
                //don't proceed
            }
        },
        async confirmRejection(id, type) {
            let proceed = confirm("Are you sure you want to Reject deleteing this " + this.replaceAll(type, '_', ' '));
            if (proceed) {
                this.loading = true
                try {
                    var apiResponse = await ApiService.RejectReport(id)
                    if (apiResponse['status'] == 'success') {
                        location.href = ''
                    } else {
                        this.notifyUser(apiResponse['status'], apiResponse['message'])
                    }
                } catch (e) {
                    let m = 'something went wrong'
                    if (e) {
                        m = e['statusText']
                    }
                    this.notifyUser('error', m)
                } finally {
                    this.loading = false
                }
            } else {
                //don't proceed
            }
        },
        async searchReports() {
            this.page = 0
            this.getReports()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getReports()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.reportsData['total_page']) {
                this.page = this.page + 1
                this.getReports()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.getReports();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "name",
                "bank",
                "report_no",
                "report_type",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getReports()
    },
    watch: {}
}
</script>
    
    
<style scoped>
    </style>
