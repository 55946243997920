<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
          </div>
          <LinearLoader v-if="loading"></LinearLoader>
          <div v-if="job" class="row p-3">
                <div class="mb-4">
                    <a
                        :href="vtApp()+'/dashboard/job/'+job.id"
                        target="_blank"
                        class="btn btn-sm btn-icon btn-info mr-3 ml-2"
                        type="button"
                        >
                        View in Viraltrend
                    </a>
                    <button
                        v-if="job.status == 'pending' || job.status == 'deactive'"
                        @click="approvePost"
                        class="btn btn-sm btn-icon btn-success"
                        type="button"
                        >
                        Approve
                    </button>
                    <button
                        v-if="job.status == 'active'"
                        @click="rejectPost"
                        class="btn btn-sm btn-icon btn-danger"
                        type="button"
                        >
                        Reject
                    </button>
                    <button
                        class="btn btn-sm btn-icon btn-primary"
                        type="button"
                        >
                        Edit
                    </button>
                    <button
                        class="btn btn-sm btn-icon btn-danger"
                        type="button"
                        >
                        Delete
                    </button>
                </div>
                <div v-if="job" class="col-xl-10 col-md-12 col-sm-12">

                    <div class="mt-2">
                        <h3 class="card-title text-uppercase text-muted mb-2">Job Info</h3>
                        <p class="">ID: {{ job.id }}</p>
                        <p class="">User ID: <a :href="'/dashboard/user/'+job.user_id ">{{ job.user_id }}</a></p>
                        <p class="" v-if="job.user">Posted By: {{ job.user.name }}</p>
                        <p class="">Job Role ID: {{ job.job_role_id }}</p>
                        <p class="" v-if="job.job_role">Job Role Name: {{ job.job_role.name }}</p>
                        <p class="">Company ID: {{ job.job_company_id }}</p>
                        <p class="" v-if="job.company">Company Name: {{ job.company.name }}</p>
                        <p class="" v-if="job.company">Company Sector: {{ job.company.sector }}</p>
                        <p class="" v-if="job.company">Company Location: {{ job.company.location }}</p>
                        <p class="" v-if="job.company">Company Website: {{ job.company.website }}</p>
                        <p class="">Min Salary: {{ job.min_salary }}</p>
                        <p class="">Max Salary: {{ job.max_salary }}</p>
                        <p class="">Image: </p>
                        <div>
                            <img style="height: 50px" :src="job.image" />
                        </div>
                        <p class="">Description: {{ job.description }}</p>
                        <p class="">Job Type ID: {{ job.job_type_id }}</p>
                        <p class="" v-if="job.job_type">Job Type Name: {{ job.job_type.name }}</p>
                        <p class="">Application Link: {{ job.application_link }}</p>
                        <p class="">Tags: {{ job.tags }}</p>
                        <p class="">Status: {{ job.status }}</p>
                    </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api_service'

  export default {
    name: "User",
    components: {
    },
    data: () => ({
      selectedItem: null,
      id: "",
      message: "",
      loading: "",
      job: "",
      fields: [
      ],
      withdrawal_fields: [
        "id",
        "user_id",
        "account_id",
        "amount",
        "wallet",
        "country",
        "status",
        "created_at"
      ]
    }),
    methods: {
      async getJob () {
        this.loading = true
        try {
          var apiResponse = await ApiService.GetJob(this.id)
          if(apiResponse['status'] == 'success'){
            this.job = apiResponse['data']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      },
      async approvePost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.ApproveJob(this.id)
        this.notifyUser(apiResponse['status'],apiResponse['message'])
          if(apiResponse['status'] == 'success'){
              location.href = ''
          }else{
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
        } finally {
          this.loading = false
        }
      },
      async rejectPost () {
        this.loading = true
        try {
          var apiResponse = await ApiService.RejectJob(this.id)
        this.notifyUser(apiResponse['status'],apiResponse['message'])
          if(apiResponse['status'] == 'success'){
              location.href = ''
          }else{
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
        } finally {
          this.loading = false
        }
      },
    },
    mounted () {
        this.id = this.$route.params['id']
        this.getJob(this.id.toString())
    }
  }
</script>

<style scoped>
</style>
