<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="ordersData['total_page']">{{ (parseInt(ordersData['total_page'])+1).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h3 v-if="!loading" class="mb-0">{{ordersData['total_orders'].toLocaleString()}} Orders</h3>
                            <template v-for="orderSection in orderSections">
                                <a v-if="orderSection == order_section" class="mr-1 mb-1 btn btn-sm btn-icon btn-success" href="javascript:;" @click="changeOrderSection(orderSection)">{{ orderSection }}</a>
                                <a v-else class="mr-1 mb-1 btn btn-sm btn-icon btn-primary" href="javascript:;" @click="changeOrderSection(orderSection)">{{ orderSection }}</a>
                            </template>
                        </div>
                        <div class="col-sm-6 px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchOrders" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th v-for="field in userFields('mini')" :key="'user_'+field" scope="col">
                                    Ordered By: {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in allOrders" :key="item.id">
                                <td v-for="field in tableHeadings" :key="field">
                                    <template v-if="field == 'image'">
                                        <a :href="item[field]" target="_blank">
                                            <img style="height:30px" :src="item[field]" />
                                        </a>
                                    </template>
                                    <template v-else-if="field.search('date_') > -1">{{ unixToUtc(item[field]) }}</template>
                                    <template v-else>{{ item[field] }}</template>
                                </td>
                                <td v-for="field in userFields('mini')" :key="'user_'+field">
                                    <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/order/'+item.id" class="btn btn-sm btn-icon btn-primary" type="button">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Orders",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        order_section: "all",
        searchResult: [],
        allOrdersData: [],
        allOrders: [],
        fields: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        ordersData: [],
        orderSections: ['accepted', 'rejected', 'shiped', 'delivered', 'received', 'confirmed', 'disputed', 'all']
    }),
    methods: {
        async getOrders() {
            this.loading = true
            try {
                let order_section = this.order_section
                if (order_section == 'all') {
                    order_section = ''
                }
                var apiResponse = await ApiService.GetOrders(this.page, this.search, order_section)
                if (apiResponse['status'] == 'success') {
                    this.ordersData = apiResponse['data']
                    this.allOrders = this.ordersData['orders']
                    this.total_page = apiResponse['data']["total_page"];
                    this.current_page = parseInt(apiResponse['data']["current_page"]);
                    if (this.current_page + 10 < this.total_page) {
                        this.total_page = this.current_page + 10
                    }
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async searchOrders() {
            this.page = 0
            this.getOrders()
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.getOrders()
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.ordersData['total_page']) {
                this.page = this.page + 1
                this.getOrders()
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async changeOrderSection(s) {
            this.order_section = s
            this.getOrders()
        },
        async openPage(a) {
            this.page = a;
            this.getOrders();
        },
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "image",
                "category",
                "item_number",
                "amount",
                "currency",
                "shipping_currency",
                "shipping_fee",
                "date_accepted",
                "date_rejected",
                "date_refunded",
                "date_paid",
                "date_confirmed",
                "date_shipped",
                "date_delivered",
                "date_received",
                "date_disputed",
                "order_id",
                "shop_id",
                "status",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
        this.getOrders()
    },
    watch: {}
}
</script>

<style scoped>
</style>
