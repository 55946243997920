<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col d-flex">
                            <h3 class="mb-0">Create Notification</h3>
                            <a href="/dashboard/notifications" class="ml-4 btn btn-success text-white">View Notifications</a>
                        </div>
                        <div class="col px-3 d-flex">
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="card-header col-sm-8 m-auto">

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="">Type</label>
                                <select v-model="type" required="required" id="" class="form-control populate  viral-select">
                                    <option value="" selected="selected">- Select User Type -</option>
                                    <option value="Unverified-user">Unverified User</option>
                                    <option value="Verified">Verified Users</option>
                                    <option value="Advertisers">Advertisers</option>
                                    <option value="Promoters">Promoters</option>
                                    <option value="Promoters-free">Promoters (Free)</option>
                                    <option value="Promoters-basic">Promoters (Basic)</option>
                                    <option value="Promoters-premium">Promoters (Premium)</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="message">Message</label>
                                <textarea v-model="message" type="text" id="message" class="form-control" required></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-control-label" for="link">Link</label>
                                <input v-model="link" type="text" id="link" class="form-control" required>
                            </div>
                        </div>
                    </div>
                    <div>

                        <button @click="createNoty" type="button" class="btn btn-primary" :disabled="loading" :data-loading="loading">
                            Create Noty
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "CreateNoty",
    components: {},
    data: () => ({
        loading: false,
        type: '',
        message: '',
        link: '',
        data: {}
    }),
    methods: {
        setPlatformType() {
            let platform_type = this.platform_type
            if (platform_type) {
                let platformTypeSplit = platform_type.split('-')
                if (platformTypeSplit.length != 2) {
                    this.notifyUser('error', 'Platform and type not understood')
                } else {
                    this.platform = platformTypeSplit[0]
                    this.type = platformTypeSplit[1]
                }
                if (platform_type.search('comment') > -1) {
                    this.show_comment = 'true'
                } else [
                    this.show_comment = 'false'
                ]
            } else {
                this.notifyUser('error', 'Platform type is required')
            }
        },
        async createNoty() {
            this.loading = true
            try {
                let data = {
                    'type': this.type,
                    'message': this.message,
                    'link': this.link,
                }
                var apiResponse = await ApiService.CreateNoty(this.type, this.message, this.link)
                if (apiResponse['status'] == 'success') {
                    this.type = ''
                    this.link = ''
                    this.message = ''

                    location.href = '/dashboard/notifications'
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
    },
    computed: {
        tableHeadings() {
            return []
        }
    },
    mounted() {},
    watch: {}
}
</script>
    
    
<style scoped>
        </style>
