import Vue from "vue"
import Vuex from "vuex"
import modules from "./modules"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  state: {},
  mutations: {},
  actions: {}
})

window.addEventListener("logout", () => {
  window.localStorage.clear()
  Object.keys(modules).forEach(m => {
    store.commit(`${m}/RESET_STATE`)
  })
})

export default store
