<template>
<div class="container-fluid mt-5">
    <div class="row">
        <LinearLoader v-if="loading"></LinearLoader>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('tasks')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Adverts
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('user_tasks')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search User Tasks
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('users')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Users
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('withdrawals')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Withdrawals
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('withdrawals_social')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Social Withdrawals
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('transactions')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Transactions
                            </button>
                        </div>
                        <div class="col px-3 d-flex">
                            <button @click="page = 0;searchApi('shops')" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search Shops
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div v-if="loading == false" class="card-header border-0">
                    <p >{{ (parseInt(total_page)).toLocaleString() }} Page(s)</p>
                </div>
                <template v-if="tasks && tasks.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">
                                        User Email
                                    </th>
                                    <th scope="col">
                                        Advert Details
                                    </th>
                                    <th scope="col">
                                        Link
                                    </th>
                                    <th scope="col">
                                        Target
                                    </th>
                                    <th scope="col">
                                        Created
                                    </th>
                                    <th scope="col">
                                        Status
                                    </th>
                                    <th scope="col">
                                        Progress
                                    </th>
                                    <th scope="col">
                                        Comment
                                    </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in tasks" :key="item.id">
                                    <td>
                                        <span v-if="item['user']">
                                            {{ item['user']['email'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="text-capitalize">
                                            {{ item['platform'] }}<br />
                                            {{ item['type'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['url'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['users_needed'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['created_at'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.status == 'complete' || item.status == 'completed'" class="badge badge-success">
                                            {{ item['status'] }}
                                        </span>
                                        <span v-else-if="item.status == 'active'" class="badge badge-warning">
                                            {{ item['status'] }}
                                        </span>
                                        <span v-else class="badge badge-danger">
                                            {{ item['status'] }}
                                        </span>
                                    </td>
                                    <td>
                                        <progress :value="advertProgress(item['users_needed'],item['users_completed'])" max="100">{{ advertProgress(item['users_needed'],item['users_completed']) }}%</progress>
                                    </td>
                                    <td>
                                        <span>
                                            {{ item['comment'] }}
                                        </span>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="users && users.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th v-for="field in userTableHeadings" :key="field" scope="col">
                                        {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,key) in users" :key="key">
                                    <td v-for="field in userTableHeadings" :key="field">
                                        <template v-if="field == 'id'">
                                            <a :href="'/dashboard/user/'+item[field]">
                                                {{ item[field] }}
                                            </a>
                                        </template>
                                        <template v-else-if="field == 'status'">
                                            <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                            <span v-else-if="item[field] == 'new'" class="badge badge-warning">{{ item[field] }}</span>
                                            <span v-else class="badge badge-danger">{{ item[field] }}</span>
                                        </template>
                                        <template v-else-if="field == 'type'">
                                            <span class="badge badge-info">{{ item[field] }}</span>
                                        </template>
                                        <template v-else>
                                            {{ item[field] }}
                                        </template>
                                    </td>
                                    <td class="text-right">
                                        <router-link :to="'/dashboard/user/' + item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                            <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="user_tasks && user_tasks.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th v-for="field in userTaskTableHeadings" :key="field" scope="col">
                                        {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in user_tasks" :key="item.id">
                                    <td v-for="field in userTaskTableHeadings" :key="field">
                                        <template v-if="field == 'id'">
                                            <a :href="'/dashboard/advert/'+item[field]">
                                                {{ item[field] }}
                                            </a>
                                        </template>
                                        <template v-else-if="field == 'status'">
                                            <span v-if="item[field] == 'complete'" class="badge badge-success">{{ item[field] }}</span>
                                            <span v-else-if="item[field] == 'pending'" class="badge badge-warning">{{ item[field] }}</span>
                                            <span v-else class="badge badge-danger">{{ item[field] }}</span>
                                        </template>
                                        <template v-else>
                                            {{ item[field] }}
                                        </template>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="withdrawals && withdrawals.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <th scope="col">User Email</th>
                                <th scope="col">Amount</th>
                                <th scope="col">VT Name</th>
                                <th scope="col">Bank Details</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Account Type</th>
                                <th scope="col">Request Date</th>
                                <th scope="col">Country</th>
                                <th scope="col">Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,key) in withdrawals" :key="'item-'+key">
                                    <td>
                                        <a :href="'/dashboard/user/'+item['user']['id']">
                                            {{ item['user']['email'] }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ parseFloat(item['amount']).toLocaleString(2) }}
                                    </td>
                                    <td>
                                        <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['name'] }}</span><br/>
                                        <span v-if="item['account']">{{ item['account']['account_no'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['bank'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['account_type'] }}</span>
                                    </td>
                                    <td>
                                        {{ item['created_at'] }}
                                        <p>
                                            <span v-if="item['status'] == 'complete'" class="badge badge-success">{{ item['status'] }}</span>
                                            <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                            <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="withdrawals_social && withdrawals_social.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <th scope="col">User Email</th>
                                <th scope="col">Amount</th>
                                <th scope="col">VT Name</th>
                                <th scope="col">Bank Details</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Account Type</th>
                                <th scope="col">Request Date</th>
                                <th scope="col">Country</th>
                                <th scope="col">Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,key) in withdrawals_social" :key="'item-'+key">
                                    <td>
                                        <a :href="'/dashboard/user/'+item['user']['id']">
                                            {{ item['user']['email'] }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ parseFloat(item['amount']).toLocaleString(2) }}
                                    </td>
                                    <td>
                                        <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['name'] }}</span><br/>
                                        <span v-if="item['account']">{{ item['account']['account_no'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['bank'] }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item['account']">{{ item['account']['account_type'] }}</span>
                                    </td>
                                    <td>
                                        {{ item['created_at'] }}
                                        <p>
                                                <span v-if="item['status'] == 'complete'" class="badge badge-success">{{ item[field] }}</span>
                                                <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item[field] }}</span>
                                                <span v-else class="badge badge-danger">{{ item[field] }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="transactions && transactions.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th v-for="field in transactionsTableHeadings" :key="field+'_trans_head'" scope="col">
                                        {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th v-for="field in userFields('mini')" :key="field+'_trans_user_head'" scope="col">
                                        user: {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in transactions" :key="item.id+'_trans'">
                                    <td v-for="field in transactionsTableHeadings" :key="field+'_trans'">
                                        <template v-if="field == 'status'">
                                            <span v-if="item[field] == 'complete' || item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                            <span v-else-if="item[field] == 'pending'" class="badge badge-warning">{{ item[field] }}</span>
                                            <span v-else class="badge badge-danger">{{ item[field] }}</span>
                                        </template>
                                        <template v-else>
                                            {{ item[field] }}
                                        </template>
                                    </td>
                                    <td v-for="field in userFields('mini')" :key="field+'_trans_user'">
                                        <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-if="shops && shops.length > 0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th v-for="field in shopsTableHeadings" :key="field+'_shop_head'" scope="col">
                                        {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th v-for="field in userFields('mini')" :key="'user-'+field+'_shop_head'" scope="col">
                                        user: {{ field.replace(/_/g, " ") }}
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in shops" :key="item.id">
                                    <td v-for="field in shopsTableHeadings" :key="field+'_shops'">
                                        <template v-if="field == 'image'">
                                            <a :href="item[field]" target="_blank">
                                                <img style="height:30px" :src="item[field]+'_shops'" />
                                            </a>
                                        </template>
                                        <template v-else-if="field == 'status'">
                                            <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                            <span v-else-if="item[field] == 'pending'" class="badge badge-warning">{{ item[field] }}</span>
                                            <span v-else class="badge badge-danger">{{ item[field] }}</span>
                                        </template>
                                        <template v-else>
                                            {{ item[field] }}
                                        </template>
                                        <template v-else>{{ item[field] }}</template>
                                    </td>
                                    <td v-for="field in userFields('mini')" :key="'user-'+field">
                                        <a v-if="item['user']" :href="'/dashboard/user/'+item['user']['id']">{{ item['user'][field] }}</a>
                                    </td>
                                    <td class="text-right">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <div v-if="loading == false" class="mb-3 mx-4 d-flex">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv"
import ApiService from '@/services/api_service'

export default {
    name: "Search",
    components: {},
    data: () => ({
        exporting: false,
        loading: false,
        search: "",
        section: "",
        allAdverts: [],
        page: 0,
        total_page: 0,
        current_page: 0,
        pagination: [],
        courses: [],
        events: [],
        blogs: [],
        feeds: [],
        forums: [],
        groups: [],
        jobs: [],
        pages: [],
        products: [],
        shops: [],
        users: [],
        transactions: [],
        withdrawals: [],
        withdrawals_social: [],
        user_tasks: [],
        tasks: [],
        pagination: [],
    }),
    methods: {
        async searchApi(section) {
            this.section = section
            this.loading = true
            try {
                var apiResponse = await ApiService.SearchApi(this.page, this.per_page, section, this.search)
                if (apiResponse['status'] == 'success') {
                    this.courses = apiResponse['data']['courses']
                    this.events = apiResponse['data']['events']
                    this.blogs = apiResponse['data']['blogs']
                    this.feeds = apiResponse['data']['feeds']
                    this.forums = apiResponse['data']['forums']
                    this.groups = apiResponse['data']['groups']
                    this.jobs = apiResponse['data']['jobs']
                    this.pages = apiResponse['data']['pages']
                    this.products = apiResponse['data']['products']
                    this.shops = apiResponse['data']['shops']
                    this.users = apiResponse['data']['users']
                    this.transactions = apiResponse['data']['transactions']
                    this.withdrawals = apiResponse['data']['withdrawals']
                    this.withdrawals_social = apiResponse['data']['withdrawals_social']
                    this.user_tasks = apiResponse['data']['user_tasks']
                    this.tasks = apiResponse['data']['tasks']
                    this.pagination = apiResponse['data']['pagination']
                    this.total_page = this.pagination['last_page']
                    this.current_page = this.pagination['current_page']
                } else {
                    this.notifyUser(apiResponse['status'], apiResponse['message'])
                }
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.searchApi(this.section)
            } else {
                this.notifyUser('warning', 'This is the first page')
            }
        },
        async nextPage() {
            if (this.page < this.total_page) {
                this.page = this.page + 1
                this.searchApi(this.section)
            } else {
                this.notifyUser('warning', 'This is the last page')
            }
        },
        async openPage(a) {
            this.page = a;
            this.searchApi();
        },
        advertProgress(a, b) {
            let c = 0
            a = parseFloat(a)
            b = parseFloat(b)
            if (a > 0 && b > 0) {
                c = parseInt((b / a) * 100)
            }
            return c
        },
    },
    computed: {
        userTableHeadings() {
            return [
                "id",
                "name",
                "username",
                "email",
                "type",
                "state",
                "country",
                "gender",
                "email_verified_at",
                "dob",
                "status",
                "created_at",
            ];
        },
        userTaskTableHeadings() {
            return [
                "id",
                "email",
                "social",
                "task",
                "user_currency",
                "user_amount",
                "status",
                "created_at",
                "updated_at"
            ]
        },
        withdrawalsTableHeadings() {
            return [
                "id",
                "user_id",
                "account_id",
                "amount",
                "wallet",
                "country",
                "status",
                "created_at",
                "updated_at",
            ];
        },
        transactionsTableHeadings() {
            return [
                "id",
                "email",
                "transaction_id",
                "price",
                "description",
                "wallet",
                "status",
                "created_at",
                "updated_at"
            ]
        },
        shopsTableHeadings() {
            return [
                "id",
                "name",
                "user_id",
                "address",
                "city",
                "state",
                "country",
                'unique_views_count',
                "status",
                "image",
                "online",
                "is_approved",
                "created_at",
                "updated_at"
            ]
        }
    },
    mounted() {
    },
    watch: {}
}
</script>

<style scoped>
</style>
