<template>
  <div class="main-content">
    <div class="container pt-8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="d-flex justify-content-center mb-4">
            <img src="/icon.png" alt="">
          </div>
          <div class="border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <form role="form" @submit.prevent="login">
                <div class="form-group mb-3">
                  <div class="input-group input-group-merge input-group-alternative">
                    <input v-model="email" class="form-control" placeholder="Email" type="email" required>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-merge input-group-alternative">
                    <input v-model="password" class="form-control" placeholder="Password" type="password" required>
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-block btn-primary mt-4" :disabled="loading" :data-loading="loading">Sign in</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api_service'

  export default {
    name: "Login",
    data: () => ({
      loading: false,
      email: "",
      password: ""
    }),
    methods: {
      async login () {
        this.loading = true
        try {
          var apiResponse = await ApiService.LoginUser(this.email, this .password)
          console.log("--apiResponse",apiResponse)
          this.notifyUser(apiResponse['status'],apiResponse['message'])
          if(apiResponse['status'] == 'success'){
            this.saveUserAndToken(apiResponse['user'])
            setTimeout(() => {
              location.href = '/dashboard/overview'
            }, 2000);
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped>
</style>
